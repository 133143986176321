/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BrandLogo from "../../../../_admin/assets/media/uTradeLogo.png";
import APICallService from "../../../../api/apiCallService";
import { AUTH } from "../../../../api/apiEndPoints";
import { APIJSON } from "../../../../api/apiJSON/auth";
import { auth } from "../../../../utils/toast";
import OtpInput from "../../../custom/otp-input/otp-input";
const VerifyPassword = (props: any) => {
  const inputRefs: any = useRef([]);
  const location: any = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState([]);
  const [timer, setTimer] = useState(60);
  const [resendLoading, setResendLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChange = (value: string, index: number) => {
    let temp: any = [...otp];
    if (value) {
      index === 3
        ? inputRefs.current[0].focus()
        : inputRefs.current[index + 1].focus();
    }
    temp[index] = value;
    setOtp(temp);
  };
  const handleResend = async () => {
    if (!timer) {
      setResendLoading(true);
      let data = {};
      if (location.state.email) {
        data = {
          email: location.state.email,
        };
      } else {
        data = {
          phoneCountry: location.state.phoneCountry,
          phone: location.state.phone,
        };
      }
      let apiService = new APICallService(
        AUTH.FORGOTPASSWORD,
        APIJSON.forgotPassword(data)
      );
      let response = await apiService.callAPI();
      if (response) {
        toast.success(auth.forgotOTPResend);
      }
      setTimer(60);
      setResendLoading(false);
    }
    // handleVerify()
  };
  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.height = "100%";
    }
    inputRefs.current[0].focus();
    if (!location.state) {
      navigate("/auth/login");
    }
    return () => {
      if (root) {
        root.style.height = "auto";
      }
    };
  }, []);
  useEffect(() => {
    let timeLeft = timer;
    if (timeLeft === 0) {
      setTimer(0);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimer(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timer]);
  const handleSubmit = async () => {
    setLoading(true);
    let data: any = { code: otp.join("") };
    if (location.state) {
      if (location.state.email) {
        data = {
          ...data,
          email: location.state.email,
        };
      } else {
        data = {
          ...data,
          phoneCountry: location.state.phoneCountry,
          phone: location.state.phone,
        };
      }
      let apiService = new APICallService(
        AUTH.VERIFYPASSWORD,
        APIJSON.verifyPassword(data)
      );
      let response = await apiService.callAPI();
      if (response) {
        toast.success(auth.verifiedOTP);
        navigate("/auth/create-password", { state: data });
      }
      setOtp([]);
      // setTimer(30)
      setLoading(false);
    }
  };
  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-lg-row-fluid py-10">
        <div className="">
          <Link
            to="/"
            className="mb-12 position-absolute top-0 start-0 mt-10 ms-10"
          >
            <img alt="Logo" src={BrandLogo} className="h-40px" />
          </Link>
        </div>
        <div className="d-flex flex-center pt-lg-0 pt-15 flex-column flex-column-fluid">
          <div className="w-lg-550px p-8 p-md-10 p-lg-15 mx-auto">
            <form className="form w-100" action="#">
              <div className="mb-6 text-center">
                <h1 className="text-dark fs-35 fw-bolder mb-3">
                  Verify your account
                </h1>
                <div className="text-dark fs-16 fw-500">
                  We’ve sent an OTP to{" "}
                  {location.state
                    ? location.state.email
                      ? location.state.email
                      : location.state.phone
                      ? location.state.phoneCountry + " " + location.state.phone
                      : ""
                    : ""}
                  <br></br>
                  Please enter it below to reset the password.
                </div>
              </div>
              <div className="d-flex flex-wrap mb-2 justify-content-center">
                <div className="d-flex flex-wrap">
                  <OtpInput
                    value={otp}
                    valueLength={4}
                    onChange={(value: string, index: number) => {
                      handleChange(value, index);
                    }}
                    inputRefs={inputRefs}
                  />
                </div>
              </div>
              <div className="text-center mt-6">
                <button
                  type="button"
                  className="btn btn-primary btn-lg br-8 w-75"
                  onClick={handleSubmit}
                  disabled={otp.length < 4}
                >
                  {loading ? (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  ) : (
                    <span className="indicator-label fs-16 fw-bolder">
                      Verify OTP
                    </span>
                  )}
                </button>
              </div>
              <div className="fs-14 fw-400 text-center mt-6 d-flex justify-content-center">
                {resendLoading ? (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...
                  </span>
                ) : (
                  <p
                    className="fs-16 fw-normal me-2"
                    style={timer ? {} : { cursor: "pointer" }}
                    onClick={handleResend}
                  >
                    Resend OTP
                  </p>
                )}
                <span className="fs-14 fw-400">
                  {timer
                    ? timer < 10
                      ? " in 00:0" + timer
                      : " in 00:" + timer
                    : ""}
                </span>
              </div>
            </form>
          </div>
        </div>
        <div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
          <div className="d-flex flex-center  fs-16">
            <span>
              Go back to
              <Link to="/auth/login" className="text-primary fs-16 fw-500">
                &nbsp;Sign in
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VerifyPassword;
