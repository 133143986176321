import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { getPageLimit } from '../../auth';
import APICallService from '../../../../api/apiCallService';
import { inventory } from '../../../../api/apiEndPoints';
import Loader from '../../../../Global/loader';
import Method from '../../../../utils/methods';
import {
  All_Products,
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  MasterProduct,
  Piece,
} from '../../../../utils/constants';
import clsx from 'clsx';
const ProductStockDetails = (props: any) => {
  const [fetchLoader, setFetchLoader] = useState(false);
  const [stock, setStock] = useState<any>([]);
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchDetails();
      setFetchLoader(false);
    })();
  }, []);
  const fetchDetails = async () => {
    let apiService = new APICallService(
      inventory.variantStockList,
      props.productDetails._id,'','','','',All_Products
    );
    let response = await apiService.callAPI();
    if (response.records.length) {
      setStock(response.records);
    }
  };
  return (
    <>
      {fetchLoader ? (
        <>
          <Row>
            <Col xs={12}>
              <Card className="border border-r10px">
                <Card.Body className="p-0 ">
                  <Row className="align-items-center h-250px">
                    <div className="d-flex justify-content-center">
                      <Loader loading={fetchLoader} />
                    </div>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className="align-items-center">
            <Col xs={12}>
              <Card className="border border-r10px">
                <Card.Body className="p-0">
                  <div className="table-responsive">
                    <table className="table align-middle table-rounded table-row-bordered gs-7 gy-6 mb-0 no-footer">
                      <thead>
                        <tr className="text-start fw-bold fs-16 gs-0 border-bottom">
                          <th
                            className="min-w-160px"
                            tabIndex={0}
                            rowSpan={1}
                            colSpan={1}
                          >
                            Products added on
                          </th>
                          <th
                            className="min-w-160px"
                            tabIndex={0}
                            rowSpan={1}
                            colSpan={1}
                          >
                            Batch & Expiry
                          </th>
                          <th
                            className="min-w-50px w-100px"
                            tabIndex={0}
                            rowSpan={1}
                            colSpan={1}
                          >
                            Carton
                          </th>
                          <th
                            className="min-w-50px min-w-125px"
                            tabIndex={0}
                            rowSpan={1}
                            colSpan={1}
                          >
                            Dozen
                          </th>
                          <th
                            className="min-w-50px min-w-125px"
                            tabIndex={0}
                            rowSpan={1}
                            colSpan={1}
                          >
                            Pieces
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stock && stock.length ? (
                          <>
                            {' '}
                            {stock.map((stockVal: any,index:number) => {
                              return (
                                <tr className={clsx("odd",Method.checkIsBefore(stockVal.expiry,Method.currentLocalDateTime())?"text-dark":"text-danger")}>
                                  
                                  
                                  <td className="fs-15 fw-600">
                                    <span className="d-block">
                                      {Method.convertDateToDDMMYYYY(
                                        stockVal._createdAt
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500">
                                      Batch {stockVal.batch} -{' '}
                                      {Method.convertDateToDDMMYYYY(
                                        stockVal.expiry
                                      )}
                                      {/* Batch 5 - 20/04/25 */}
                                    </span>
                                  </td>
                                  <td>
                                    {stockVal.quantityTypes.some(
                                      (item: any, idx: number) =>
                                        item.type === CartonWithDozens ||
                                        item.type === CartonWithPieces
                                    ) ? (
                                      <span
                                        className={clsx('fs-15 fw-600 d-block')}
                                      >
                                        {
                                          stockVal.quantityTypes.find(
                                            (item: any) =>
                                              item.type === CartonWithDozens ||
                                              item.type === CartonWithPieces
                                          ).stockCount
                                        }{' '}
                                      </span>
                                    ) : (
                                      <span className="fs-15 fw-600  d-block">
                                        -
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {stockVal.quantityTypes.some(
                                      (item: any, idx: number) =>
                                        item.type === Dozen
                                    ) ? (
                                      <span
                                        className={clsx('fs-15 fw-600 d-block')}
                                      >
                                        {
                                          stockVal.quantityTypes.find(
                                            (item: any) => item.type === Dozen
                                          ).stockCount
                                        }{' '}
                                      </span>
                                    ) : (
                                      <span className="fs-15 fw-600  d-block">
                                        -
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {stockVal.quantityTypes.some(
                                      (item: any, idx: number) =>
                                        item.type === Piece
                                    ) ? (
                                      <span
                                        className={clsx('fs-15 fw-600 d-block')}
                                      >
                                        {
                                          stockVal.quantityTypes.find(
                                            (item: any) => item.type === Piece
                                          ).stockCount
                                        }{' '}
                                      </span>
                                    ) : (
                                      <span className="fs-15 fw-600  d-block">
                                        -
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <tr>
                            <td colSpan={4}>
                              <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                No Data found
                              </div>
                            </td>
                          </tr>
                        )}{' '}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default ProductStockDetails;
