import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CrossSvg from "../../_admin/assets/media/close.png";

const GoodsNote = (props: any) => {

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-lg-590px"
        className="border-r10px"
      >
        <Modal.Header className="border-bottom-0 pb-0 text-center mx-auto">
          <img
            className="close-inner-top"
            width={40}
            height={40}
            src={CrossSvg}
            alt="closebutton"
            onClick={props.onHide}
          />
          <Modal.Title className="fs-26 fw-bolder mw-lg-375px">
            Discounted goods note
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-5 mb-4">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="bg-f9f9f9 text-center border-r10px p-3">
                <span className="fs-18 fw-500">The reason for selling goods at discounted rate will be shown here.</span>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer className='justify-content-center pt-5 py-8 mb-4 border-top-0'>
          <Button variant='' className='btn btn-flush text-gray fs-16 fw-600' onClick={handleClose}>
            Edit required info.
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  )

}

export default GoodsNote;
