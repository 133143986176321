import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CrossSvg from "../../_admin/assets/media/close.png";

const InfoRequired = (props: any) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-lg-590px"
        className="border-r10px"
        contentClassName="p-5"
      >
        <Modal.Header className="border-bottom-0 text-center pb-6 mx-auto">
          <div className="symbol symbol-md-40px symbol-35px close-inner-top">
            <img
              width={40}
              height={40}
              src={CrossSvg}
              alt=""
              onClick={props.onHide}
            />
          </div>
          <Modal.Title className="fs-26 fw-bolder mw-lg-350px">
            Information required from {props.name}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <Form.Group>
            <Form.Control
              className="form-control-custom"
              as="textarea"
              rows={5}
              placeholder="Please type the the information you require here…"
              onChange={props.onMessageChange}
              value={props.message}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer className="justify-content-center pt-1 border-top-0">
          <Button
            variant="primary"
            size="lg"
            onClick={props.onSave}
            disabled={!props.message.trimStart().length}
          >
            {props.loading ? (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              "Request for this info"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InfoRequired;
