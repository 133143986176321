export type Props = {
  value: string;
  valueLength: number;
  autoFocus?: boolean;
  onChange: (value: string) => void;
};
export default function OtpInput(props: any) {
  return (
    <div className="otp-group">
      {[1, 2, 3, 4].map((digit, index) => (
        <input
          key={index}
          type="text"
          autoComplete="one-time-code"
          className="otp-input fs-22 fw-600 h-60px w-60px"
          value={props.value.length ? props.value[index] : ""}
          style={{ border: "solid 0.5px #e0e0df" }}
          onChange={(e: any) => {
            props.onChange(e.target.value, index);
          }}
          maxLength={1}
          autoFocus
          ref={(el: any) => (props.inputRefs.current[index] = el)}
          name={index.toString()}
        />
      ))}
    </div>
  );
}
