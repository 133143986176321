import { Modal, Button, Card, Col, Row } from 'react-bootstrap';
import CrossSvg from '../../_admin/assets/media/close.png';
import { IMAGES } from '../../utils/dummyJSON';
import { useState } from 'react';
import clsx from 'clsx';
const GeneratePlan = (props: any) => {
  const [availableVehicle, setAvailableVehicle] = useState<any>(true);
  const [validation, setValidation] = useState<any>(false);
  const [show, setShow] = useState<boolean>(false);
  return (
    <>
      <Modal
        {...props}
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-lg-850px"
        className="border-r10px"
        contentClassName="p-5"
        centered
        backdrop={props.loading ? 'static' : true}
      >
        <Modal.Header className="border-bottom-0 pb-0 text-center mx-auto">
          <img
            className="close-inner-top"
            width={40}
            height={40}
            src={CrossSvg}
            alt="closebutton"
            onClick={props.onHide}
          />
          <Modal.Title className="fs-26 fw-bolder mw-lg-425px pt-lg-3">
            Are you sure you want to generate this plan?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {props.discardedOrders.length ? (
              <div className="row justify-content-center mb-4">
                <div className="col-lg-10">
                  <div className="bg-light-danger text-center border-r10px p-3">
                    <span className="fs-18 fw-500">
                      {`By continuing, ${props.discardedOrders.length} orders are due to either vehicle capacity or the time duration needed to deliver the order, which will not fit in any truck`}{' '}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {!props.availableVehicle ? (
              <div className="row justify-content-center mb-4">
                <div className="col-lg-10">
                  <div className="bg-light-warning text-center border-r10px p-3">
                    <span className="fs-18 fw-500">
                      {`Depending on the vehicles you choose, the route may change, and the order in which vehicles are selected and loaded may also be affected.`}{' '}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {props.availableVehicles.length ? (
              <>
                {' '}
                <Col
                  xs={12}
                  className="d-flex justify-content-center"
                >
                  <div className="form-check form-check-custom form-check-solid me-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="appType"
                      value="Quick Online Courses"
                      checked={props.availableVehicle}
                      onChange={() => {
                        props.setAvailableVehicle(true);
                      }}
                    />
                    <label className="form-check-label fs-16 fw-600 text-dark">
                      Plan Route with system preference
                    </label>
                  </div>
                  <div className="form-check form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="appType"
                      checked={!props.availableVehicle}
                      value="Quick Online Courses"
                      onChange={() => {
                        props.setAvailableVehicle(false);
                      }}
                    />
                    <label className="form-check-label fs-16 fw-600 text-dark">
                      Plan route with Selected Vehicles
                    </label>
                  </div>
                </Col>
                {!props.availableVehicle ? (
                  <>
                    <Card className="p-0 mt-4">
                      <Card.Header
                        className={clsx(
                          'border h-35px',
                          show ? '' : 'border-r10px'
                          // generalDataValidation.combinations[index].validation
                          //   ? "border-danger"
                          //   : ""
                        )}
                      >
                        <Row className="fs-16 fw-600 align-items-center w-100">
                          <Col
                            md
                            className="mb-md-0 mb-5 text-start"
                          >
                            {' '}
                            <h1>Select Vehicle</h1>
                          </Col>{' '}
                          {props.selectedVehicle.length ? (
                            <Col
                              md
                              className="mb-md-0 mb-5 text-start"
                            >
                              <div className="bg-light-primary w-fit-content p-1 px-2 mb-2 border-r5px">
                                <span className="text-primary fs-14 fw-600">
                                  {props.selectedVehicle.length}{' '}
                                  {props.selectedVehicle.length == 1
                                    ? 'vehicle'
                                    : 'vehicles'}{' '}
                                  selected
                                </span>
                              </div>
                            </Col>
                          ) : (
                            <></>
                          )}
                          <Col md="auto">
                            <div className="d-flex flex-row">
                              <div className="d-flex align-items-center">
                                {show ? (
                                  <Button
                                    variant="link"
                                    className="btn-flush"
                                    onClick={() => {
                                      setShow(!show);
                                    }}
                                  >
                                    <img
                                      src={IMAGES.ArrowUp}
                                      alt=""
                                    />
                                  </Button>
                                ) : (
                                  <Button
                                    variant="link"
                                    className="btn-flush"
                                    onClick={() => {
                                      setShow(!show);
                                    }}
                                  >
                                    <img
                                      src={IMAGES.ArrowDown}
                                      alt=""
                                    />
                                  </Button>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card.Header>
                      {show ? (
                        <Card.Body className="p-0">
                          <div className="table-responsive">
                            <table className="table table-rounded table-row-bordered align-middle gs-9 gy-4 mb-0">
                              <thead>
                                <tr className="fs-16 fw-600 align-middle">
                                  <th
                                    className="min-w-50"
                                    tabIndex={0}
                                    rowSpan={1}
                                    colSpan={1}
                                  >
                                    <div className="form-check form-check-custom form-check-solid">
                                      <input
                                        className="form-check-input h-22px w-22px"
                                        type="checkbox"
                                        value="1"
                                        checked={
                                          props.selectedVehicle.length ==
                                          props.availableVehicles.length
                                        }
                                        onChange={(event: any) => {
                                          props.handleSelectAll();
                                        }}
                                      />
                                      <label className="form-check-label fs-16 fw-600 text-dark"></label>
                                    </div>
                                  </th>
                                  <th className="min-w-175px">Vehicle name</th>
                                  <th className="min-w-70px">Vehicle number</th>
                                  <th className="w-md-225px">Dimensions</th>
                                  <th className="min-w-md-100px ">Weight</th>
                                </tr>
                              </thead>
                              <tbody className="fs-15 fw-600">
                                <>
                                  {props.availableVehicles.map((val: any) => {
                                    return (
                                      <tr>
                                        <td>
                                          <div className="form-check form-check-custom form-check-solid">
                                            <input
                                              className={clsx(
                                                'form-check-input',
                                                validation
                                                  ? 'border border-danger'
                                                  : ''
                                              )}
                                              type="checkbox"
                                              name="appType"
                                              checked={props.selectedVehicle.includes(
                                                val._id
                                              )}
                                              onClick={() => {
                                                props.handleVehicleSelect(
                                                  val._id
                                                );
                                              }}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <div className="symbol symbol-md-50px symbol-30px">
                                              <span
                                                className="symbol-label"
                                                style={{
                                                  backgroundImage: `url(${val.image})`,
                                                }}
                                              ></span>
                                            </div>
                                            <div className="ms-3">
                                              <span>{val.name}</span>
                                              <div className="fs-14 fw-500 text-dark">
                                                {val.user.name}
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <span>{val.number}</span>
                                        </td>
                                        <td>
                                          <span>
                                            {val.dimensions.length} (L),{' '}
                                            {val.dimensions.width} (W),{' '}
                                            {val.dimensions.height} (H)
                                          </span>
                                        </td>
                                        <td>
                                          <span>
                                            {val.dimensions.weight} tons
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              </tbody>
                            </table>
                          </div>
                        </Card.Body>
                      ) : (
                        <></>
                      )}
                    </Card>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mt-2 mb-4 border-top-0">
          <Button
            size="lg"
            onClick={props.onSave}
            disabled={props.loading}
          >
            {!props.loading && (
              <span className="indicator-label"> Yes, Generate Plan</span>
            )}
            {props.loading && (
              <span
                className="indicator-progress"
                style={{ display: 'block' }}
              >
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default GeneratePlan;
