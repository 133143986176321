import { Card, Col, Row, Table } from 'react-bootstrap';
import carton from '../../../../_admin/assets/media/product/cartoon.png';
import {
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  Piece,
} from '../../../../utils/constants';
import Method from '../../../../utils/methods';
const TabPriceDimensions = (props: any) => {
  return (
    <>
      {props.productDetails.dozensInCarton ||
      props.productDetails.piecesInDozen ||
      props.productDetails.piecesInCarton ? (
        <Card className="border mb-8">
          <Card.Header className="bg-light align-items-center">
            <h3 className="fs-22 fw-bolder mb-0">
              The product is sold in{' '}
              {props.productDetails.dozensInCarton ? 'Dozens ' : ' '}
              {props.productDetails.piecesInCarton ||
              props.productDetails.piecesInDozen
                ? 'Pieces'
                : 'Cartons '}
            </h3>
          </Card.Header>
          <Card.Body>
            <Row className="align-items-center">
              <Col lg={2}>
                <label className="fs-16 fw-500 text-dark">
                  Quantity details:
                </label>
              </Col>
              <div className="col-lg-10">
                <div className="d-flex align-items-center">
                  {props.productDetails.dozensInCarton ? (
                    <>
                      <div className="d-flex justify-content-center align-items-center w-fit-content bg-gray-100 p-4 px-4 pills-radius me-3">
                        <span className="fw-600 text-black fs-16">
                          {props.productDetails.dozensInCarton} dozens are in a
                          carton
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {props.productDetails.piecesInCarton ? (
                    <>
                      <div className="d-flex justify-content-center align-items-center w-fit-content bg-gray-100 p-4 px-4 pills-radius me-3">
                        <span className="fw-600 text-black fs-16">
                          {props.productDetails.piecesInCarton} pieces are in a
                          carton
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {props.productDetails.piecesInDozen ? (
                    <>
                      <div className="d-flex justify-content-center align-items-center w-fit-content bg-gray-100 p-4 px-4 pills-radius me-3">
                        <span className="fw-600 text-black fs-16">
                          {props.productDetails.piecesInDozen} pieces are in a
                          dozen
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
      {props.productDetails.quantityTypes.map((quantityVal: any) => {
        return (
          <>
            <Card className="border mb-8">
              <Card.Header className="bg-light align-items-center">
                <h3 className="fs-22 fw-bolder mb-0">
                  {' '}
                  <span>
                    <img
                      src={carton}
                      width={27}
                      height={24}
                      alt=""
                    />
                  </span>{' '}
                  {quantityVal.type === CartonWithDozens ||
                  quantityVal.type === CartonWithPieces
                    ? 'Carton details'
                    : ''}
                  {quantityVal.type === Dozen ? 'Dozen details' : ''}
                  {quantityVal.type === Piece ? 'Piece details' : ''}
                </h3>
                <Col sm="auto">
                  {quantityVal.discountAmtEnabled ? (
                    <>
                      <span className="fs-22 fw-600">
                        TSh{' '}
                        {Method.getGeneralizedAmount(quantityVal.discountAmt)}
                      </span>
                      <span className="fs-18 fw-500 text-gray text-decoration-line-through ms-3">
                        TSh {Method.getGeneralizedAmount(quantityVal.amount)}
                      </span>{' '}
                    </>
                  ) : (
                    <>
                      <span className="fs-22 fw-600">
                        TSh {Method.getGeneralizedAmount(quantityVal.amount)}
                      </span>
                    </>
                  )}
                </Col>
              </Card.Header>
              <Card.Body>
                <Row className="mb-7">
                  <Col lg={2}>
                    <label className=" fs-16 fw-500 text-dark">
                      Original price:
                    </label>
                  </Col>
                  <Col lg={10}>
                    <span className="fw-bold fs-16 fw-600 text-dark">
                      TSh {Method.getGeneralizedAmount(quantityVal.amount)} /{' '}
                      {quantityVal.type === CartonWithDozens ||
                      quantityVal.type === CartonWithPieces
                        ? 'Carton'
                        : ''}
                      {quantityVal.type === Dozen ? 'Dozen' : ''}
                      {quantityVal.type === Piece ? 'Piece' : ''}
                    </span>
                  </Col>
                </Row>
                {quantityVal.discountByQuantitiesEnabled ? (
                  <>
                    <Row className="mb-7">
                      <Col lg={2}>
                        <label className=" fs-16 fw-500 text-dark">
                          Discount by total number of quantities:
                        </label>
                      </Col>
                      <Col lg={'auto'}>
                        <Table
                          responsive
                          className="table-rounded table-row-bordered border align-middle border-r10px gs-5 mb-0"
                        >
                          <thead>
                            <tr className="fs-16 fw-bold">
                              <th>Minimum qty.</th>
                              <th>Maximum qty.</th>
                              <th>
                                Discounted price of a{' '}
                                {quantityVal.type === CartonWithDozens ||
                                quantityVal.type === CartonWithPieces
                                  ? 'carton'
                                  : ''}
                                {quantityVal.type === Dozen ? 'dozen' : ''}
                                {quantityVal.type === Piece ? 'piece' : ''}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {quantityVal.discountsByQuantities.map(
                              (disVal: any) => {
                                return (
                                  <>
                                    <tr>
                                      <td>
                                        <span className="fs-16 fw-500">
                                          {disVal.min}{' '}
                                          {quantityVal.type ===
                                            CartonWithDozens ||
                                          quantityVal.type === CartonWithPieces
                                            ? 'carton'
                                            : ''}
                                          {quantityVal.type === Dozen
                                            ? 'dozen'
                                            : ''}
                                          {quantityVal.type === Piece
                                            ? 'piece'
                                            : ''}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fs-16 fw-500">
                                          {disVal.max}{' '}
                                          {quantityVal.type ===
                                            CartonWithDozens ||
                                          quantityVal.type === CartonWithPieces
                                            ? 'carton'
                                            : ''}
                                          {quantityVal.type === Dozen
                                            ? 'dozen'
                                            : ''}
                                          {quantityVal.type === Piece
                                            ? 'piece'
                                            : ''}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fs-16 fw-500">
                                          {' '}
                                          TSh{' '}
                                          {Method.getGeneralizedAmount(
                                            disVal.discountAmt
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
                <Row className="align-items-center">
                  <Col lg={2}>
                    <label className="fs-16 fw-500 text-dark">
                      {quantityVal.type === CartonWithDozens ||
                      quantityVal.type === CartonWithPieces
                        ? 'Carton'
                        : ''}
                      {quantityVal.type === Dozen ? 'Dozen' : ''}
                      {quantityVal.type === Piece ? 'Piece' : ''} dimensions:
                    </label>
                  </Col>
                  <Col lg={10}>
                    <div className="d-flex flex-lg-row flex-wrap align-items-center">
                      <div className="d-flex justify-content-center align-items-center w-fit-content bg-gray-100 p-4 px-4 pills-radius me-3 mb-3">
                        <span className="fw-600 text-black fs-16">
                          Length: {quantityVal.dimensions.length} cm
                        </span>
                      </div>
                      <div className="d-flex justify-content-center align-items-center w-fit-content bg-gray-100 p-4 px-4 pills-radius me-3 mb-3">
                        <span className="fw-600 text-black fs-16">
                          Width: {quantityVal.dimensions.width} cm
                        </span>
                      </div>
                      <div className="d-flex justify-content-center align-items-center w-fit-content bg-gray-100 p-4 px-4 pills-radius me-3 mb-3">
                        <span className="fw-600 text-black fs-16">
                          Height: {quantityVal.dimensions.height} cm
                        </span>
                      </div>
                      {quantityVal.dimensions.weight > 0 ? (
                        <>
                          <div className="d-flex justify-content-center align-items-center w-fit-content bg-gray-100 p-4 px-4 pills-radius me-3 mb-3">
                            <span className="fw-600 text-black fs-16">
                              Weight: {quantityVal.dimensions.weight} kg
                            </span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </>
        );
      })}
    </>
  );
};
export default TabPriceDimensions;
