import { Card, Col, Row } from 'react-bootstrap';
import Method from '../../../../utils/methods';
import clsx from 'clsx';
import {
  DateSelection,
  MultiLineText,
  SingleLineText,
} from '../../../../utils/constants';
import { useEffect, useState } from 'react';
const TabProductDetails = (props: any) => {
  const [technicalInfo, setTechnicalInfo] = useState<any>([]);
  useEffect(() => {
    if (props.productDetails.technicalInfo.length > 0) {
      let temp: any = [];
      props.productDetails.technicalInfo.map((techVal: any) => {
        if (techVal.enabled) {
          temp.push(techVal);
        }
      });
      setTechnicalInfo(temp);
    }
  }, [props.productDetails]);
  return (
    <>
      <Card className="border mb-8">
        <Card.Header className="bg-light align-items-center">
          <h3 className="fs-22 fw-bolder mb-0">Basic details</h3>
        </Card.Header>
        <Card.Body>
          <Row className="mb-7">
            <Col lg={2}>
              <label className=" fs-16 fw-500 text-dark">Product title:</label>
            </Col>
            <Col lg={10}>
              <span className="fw-bolder fs-16 fw-600 text-dark">
                {props.productDetails.title.replace(/\s*\)\s*/g, ')')}{' '}
              </span>
            </Col>
          </Row>
          <Row className="mb-7">
            <Col lg={2}>
              <label className=" fs-16 fw-500 text-dark">
                Product added on:
              </label>
            </Col>
            <Col lg={10}>
              <span className="fs-16 fw-600 text-dark">
                {Method.convertDateToDDMMYYYYHHMM(
                  props.productDetails._createdAt
                )}
              </span>
            </Col>
          </Row>
          <Row className="mb-7">
            <Col lg={2}>
              <label className=" fs-16 fw-500 text-dark">Brand:</label>
            </Col>
            <Col lg={10}>
              <span className="fs-16 fw-600 text-dark">
                {props.productDetails.product.brand.title}
              </span>
            </Col>
          </Row>
          <Row className="mb-7">
            <Col lg={2}>
              <label className=" fs-16 fw-500 text-dark">
                Country of origin:
              </label>
            </Col>
            <Col lg={10}>
              <span className="fs-16 fw-600 text-dark">
                {props.productDetails.product.country.name}
              </span>
            </Col>
          </Row>
          <Row className="mb-7">
            <Col lg={2}>
              <label className=" fs-16 fw-500 text-dark">
                Product Description:
              </label>
            </Col>
            <div className="col-lg-10">
              <span className="fs-16 fw-600 text-dark">
                {props.productDetails.product.description}
              </span>
            </div>
          </Row>
          <Row className="mb-7 align-items-center">
            <Col lg={2}>
              <label className="fs-16 fw-500 text-dark">
                Product category:
              </label>
            </Col>
            <div className="col-lg-10">
              <div className="d-flex align-items-center">
                <div className="d-flex justify-content-center align-items-center w-fit-content bg-gray-100 p-4 px-4 pills-radius me-3">
                  <span className="fw-600 text-black fs-16">
                    Primary category:{' '}
                    {props.productDetails.product.category.title}
                  </span>
                </div>
                <div className="d-flex justify-content-center align-items-center w-fit-content bg-gray-100 p-4 px-4 pills-radius me-3">
                  <span className="fw-600 text-black fs-16">
                    Sub category:{' '}
                    {props.productDetails.product.subCategory.title}
                  </span>
                </div>
                {props.productDetails.product.groupCategory ? (
                  <div className="d-flex justify-content-center align-items-center w-fit-content bg-gray-100 p-4 px-4 pills-radius me-3">
                    <span className="fw-600 text-black fs-16">
                      Group category:{' '}
                      {props.productDetails.product.groupCategory.title}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Row>
          {props.productDetails.product.taxFree ? (
            <></>
          ) : (
            <>
              {props.productDetails.product.productTax ? (
                <Row className="mb-7 align-items-center">
                  <Col lg={2}>
                    <label className="fs-16 fw-500 text-dark">
                      Product tax:
                    </label>
                  </Col>
                  <div className="col-lg-10">
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-center align-items-center w-fit-content p-4 px-4 me-3">
                        <span className="fw-bolder fs-16 fw-600 text-dark">
                          {props.productDetails.product.productTax} %
                        </span>
                      </div>
                    </div>
                  </div>
                </Row>
              ) : (
                <></>
              )}
            </>
          )}
        </Card.Body>
      </Card>
      {technicalInfo.length > 0 ? (
        <Card className="border mb-8">
          <Card.Header className="bg-light align-items-center">
            <h3 className="fs-22 fw-bolder mb-0">Technical details</h3>
          </Card.Header>
          <Card.Body className={clsx('pb-3')}>
            {technicalInfo.map((techVal: any, index: number) => {
              return (
                <>
                  {techVal.enabled ? (
                    <>
                      <Row className="mb-7 align-items-center">
                        <Col lg={2}>
                          <label className=" fs-16 fw-500 text-dark">
                            {techVal.name}:
                          </label>
                        </Col>
                        {techVal.type === SingleLineText ||
                        techVal.type === MultiLineText ||
                        techVal.type === DateSelection ? (
                          <Col lg={10}>
                            <span className="fw-bolder fs-16 fw-600 text-dark">
                              {techVal.type === DateSelection
                                ? Method.convertDateToDDMMYYYY(techVal.answer)
                                : techVal.answer}{' '}
                            </span>
                          </Col>
                        ) : (
                          <div className="col-lg-9">
                            <div className="d-flex align-items-center">
                              {techVal.options.map((optionVal: any) => {
                                return (
                                  <div className="d-flex justify-content-center align-items-center w-fit-content bg-gray-100 p-4 px-4 pills-radius me-3">
                                    <span className="fw-600 text-black fs-16">
                                      {optionVal.title}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
      <Card className="border mb-8">
        <Card.Header className="bg-light align-items-center">
          <h3 className="fs-22 fw-bolder mb-0">Product images</h3>
        </Card.Header>
        <Card.Body className="pb-4">
          <Row>
            {props.productDetails.media.map((mediaVal: any) => {
              return (
                <Col
                  xs="auto"
                  className="mb-6"
                >
                  <div className="symbol symbol-md-200px symbol-150px w-fit-content border bg-body">
                    <img
                      className="img-fluid object-fit-contain"
                      src={mediaVal.url}
                      alt=""
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
export default TabProductDetails;
