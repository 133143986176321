import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import InterTransferRequest from './inter-transfer-request';
import CreateInterTransferRequest from './create-inter-transfer-request';
import InterTransferDetail from './inter-transfer-detail';
const InterTransferRequestPage = () => (
  <Routes>
    <Route element={<Outlet />} />
    <Route
      index
      element={<InterTransferRequest />}
    />
    <Route
      path="create-transfer-request"
      element={
        <>
          <CreateInterTransferRequest />
        </>
      }
    />
    <Route
      path="inter-transfer-detail"
      element={
        <>
          <InterTransferDetail />
        </>
      }
    />
  </Routes>
);
export default InterTransferRequestPage;
