import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { PageTitle } from '../../../_admin/layout/core';
import AddNewStaff from './add-new-staff';
import CustomerService from './customer-service';
import MemberDetails from './member-details';
import DistricDetails from './district/distric-details';
import SellingProducts from './selling-products';
import EditStaff from './edit-new-staff';
import MyTeam from '../my-team/my-team';
const Index = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        {/* <Route
          path="*"
          element={<Navigate to="/error/404" />}
        /> */}
        <Route
          index
          element={<CustomerService />}
        />
        <Route
          path="/add-new-staff"
          element={
            <>
              <PageTitle>Customer service</PageTitle>
              <AddNewStaff />
            </>
          }
        />
        <Route
          path="/edit-staff"
          element={
            <>
              <PageTitle>Customer service</PageTitle>
              <EditStaff />
            </>
          }
        />
        <Route
          path="/member-details"
          element={
            <>
              <PageTitle>Member details</PageTitle>
              <MemberDetails />
            </>
          }
        />
        <Route
          path="/district-details"
          element={
            <>
              <PageTitle>District details</PageTitle>
              <DistricDetails />
            </>
          }
        />
        <Route
          path="/member-details"
          element={
            <>
              <MemberDetails />
            </>
          }
        />
        <Route
          path="/selling-products"
          element={
            <>
              <PageTitle>Selling products</PageTitle>
              <SellingProducts />
            </>
          }
        />
        <Route
          path="/my-team"
          element={
            <>
              <PageTitle>customer profile</PageTitle>
              <MyTeam />
            </>
          }
        />
      </Route>
    </Routes>
  );
};
export default Index;
