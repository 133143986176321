import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import CrossSvg from '../../_admin/assets/media/close.png';
import { HomePage, SingleProduct } from '../../utils/constants';
import Method from '../../utils/methods';
import { IMAGES } from '../../utils/dummyJSON';
import { AUTH } from '../../api/apiEndPoints';
import { useState } from 'react';
import APICallService from '../../api/apiCallService';
import clsx from 'clsx';
export const PaymentOfOrderRefunds = (props: any) => {
  const [password, setPassword] = useState('');
  const [valid, setValid] = useState(false);
  const [show, setShow] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const checkPassword = async () => {
    if (password.length) {
      setPasswordLoading(true);
      let apiService = new APICallService(AUTH.checkPassword, {
        password: password,
      },'','','','',props.moduleName);
      let response = await apiService.callAPI();
      if (response) {
        props.markAsRefund();
      }
      setPasswordLoading(false);
    } else {
      setValid(false);
    }
  };
  return (
    <Modal
      {...props}
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog-centered min-w-lg-690px"
      className="border-r10px"
      centered
      backdrop={props.refundLoading ? 'static' : true}
    >
      <Modal.Header className="border-bottom-0 pb-0 text-center mx-auto">
        <img
          className="close-inner-top"
          width={40}
          height={40}
          src={CrossSvg}
          alt="closebutton"
          onClick={props.onHide}
        />
        <Modal.Title className="fs-26 fw-bolder mw-lg-400px pt-lg-3">
          Make a payment of order refunds{' '}
          {props.state.refundVal.business.name
            ? props.state.refundVal.business.name
            : props.user.business.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-auto">
        <Row className="px-lg-6 g-5">
          <Col lg={6}>
            <div className="border border-r8px p-5">
              <h3 className="fs-22 fw-bolder mb-1">
                {props.selectedData.length} orders
              </h3>
              <span className="fs-16 fw-500 text-gray">Total orders</span>
            </div>
          </Col>
          <Col lg={6}>
            <div className="border border-r8px p-5">
              <h3 className="fs-22 fw-bolder mb-1">
                TSh{' '}
                {Method.getGeneralizedAmount(
                  props.selectedData.reduce(
                    (total: any, item: any) =>
                      total +
                      (item.payment.adjustmentCharge
                        ? item.payment.adjustmentCharge
                        : item.payment.receivedCharge),
                    0
                  )
                )}
              </h3>
              <span className="fs-16 fw-500 text-gray">Total amount</span>
            </div>
          </Col>
          <Col xs={12}>
            <Form.Label className="fs-16 fw-600">
              Please enter your account password to mark as refunded.
            </Form.Label>
            <InputGroup
              className={clsx(
                'border border border-r8px mw-md-294px',
                valid ? 'border-danger' : ''
              )}
            >
              <Form.Control
                className="border-0 form-control-custom text-dark"
                placeholder="Type here…"
                type={show ? 'text' : 'password'}
                value={password}
                onChange={(event: any) => {
                  if (event.target.value.trimStart().length) {
                    setValid(false);
                  }
                  setPassword(event.target.value.trimStart());
                }}
              />
              <InputGroup.Text className="border-0">
                <Button
                  variant="link"
                  className="btn-flush"
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                  {show ? (
                    <img
                      width={25}
                      height={16}
                      src={IMAGES.eyeBlue}
                      alt=""
                    />
                  ) : (
                    <img
                      width={25}
                      height={16}
                      src={IMAGES.eyeGray}
                      alt=""
                    />
                  )}
                </Button>
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <div className="d-flex justify-content-center py-4">
            <Button
              size="lg"
              className="min-h-60px"
              onClick={checkPassword}
            >
              {(!props.refundLoading || !passwordLoading) && (
                <span className="indicator-label"> Mark as refunded</span>
              )}
              {(props.refundLoading || passwordLoading) && (
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </Button>
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
export default PaymentOfOrderRefunds;
