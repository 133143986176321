import { useState } from 'react';
import { Button, Card, Col, Form, FormLabel, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { KTSVG } from '../../../../_admin/helpers';
import { IMAGES } from '../../../../utils/dummyJSON';
import { CustomSelect } from '../../../custom/Select/CustomSelect';
import { CustomSelectTable2 } from '../../../custom/Select/custom-select-table';
import BatchExpiery from '../../../modals/batch-expiry';
import GoodsNote from '../../../modals/goods-note';

const DiscountedProducts = () => {
  const navigate = useNavigate();

  const [expieryModal, setExpieryModal] = useState(false);
  const [goodsNote, setGoodsNote] = useState(false);

  return (
    <>
      <div className="p-9">
        <BatchExpiery
          show={expieryModal}
          onHide={() => setExpieryModal(false)}
        />

        <GoodsNote
          show={goodsNote}
          onHide={() => setGoodsNote(false)}
        />

        {/* SELLER INVENTORY */}

        <div className="fs-22 fw-bolder mb-7">Discounted products</div>

        <Row className="g-6 mb-7">
          <Col
            xl={3}
            md={6}
            sm={4}
          >
            <div className="border-r8px bg-d4e1fc rounded py-4 px-5">
              <div className="d-flex align-items-center">
                <div className="fs-22 fw-bolder">27 products</div>
              </div>
              <div className="fw-500 fs-16">Products available</div>
            </div>
          </Col>
          <Col
            xl={3}
            md={6}
            sm={4}
          >
            <div className="border-r8px bg-ccebfd rounded py-4 px-5">
              <div className="d-flex align-items-center">
                <div className="fs-22 fw-bolder">236 cartons</div>
              </div>
              <div className="fw-500 fs-16">Cartons available</div>
            </div>
          </Col>
          <Col
            xl={3}
            md={6}
            sm={4}
          >
            <div className="border-r8px bg-ccf3f0 rounded py-4 px-5">
              <div className="d-flex align-items-center">
                <div className="fs-22 fw-bolder">104 dozens</div>
              </div>
              <div className="fw-500 fs-16">Dozens available</div>
            </div>
          </Col>
          <Col
            xl={3}
            md={6}
            sm={4}
          >
            <div className="border-r8px bg-fbebbc rounded py-4 px-5">
              <div className="d-flex align-items-center">
                <div className="fs-22 fw-bolder">142 pieces</div>
              </div>
              <div className="fw-500 fs-16">Pieces available</div>
            </div>
          </Col>
        </Row>

        <Card className="bg-light border mb-7">
          <Card.Body className="px-7">
            <Row className="align-items-center g-5">
              <Col
                md={6}
                lg={4}
              >
                <FormLabel className="fs-16 fw-500 text-dark">
                  Search products
                </FormLabel>
                <div className="position-relative">
                  <KTSVG
                    path="/media/icons/duotune/general/gen021.svg"
                    className="svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-13"
                  />
                  <input
                    type="text"
                    className="form-control form-control-custom borde-r8px bg-white ps-11"
                    name="Search by product name…"
                    placeholder="Search by seller name…"
                  />
                </div>
              </Col>
              <Col
                md={6}
                lg={4}
              >
                <FormLabel className="fs-16 fw-500 text-dark">
                  Filter by seller
                </FormLabel>
                <CustomSelect backgroundColor="#ffff" />
              </Col>
              <Col
                md={6}
                lg={4}
              >
                <FormLabel className="fs-16 fw-500 text-dark">
                  Filter by expiry period
                </FormLabel>
                <CustomSelect backgroundColor="#ffff" />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="border border-r10px">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table
                className="table table-rounded table-row-bordered align-middle
                            gs-7 gy-4 mb-0"
              >
                <thead>
                  <tr className="fs-16 fw-600 h-65px align-middle">
                    <th className="min-w-300px">Product name</th>
                    <th className="min-w-200px">Available stock</th>
                    <th className="min-w-200px">
                      Expire stock <br className="br" /> notify before
                    </th>
                    <th className="min-w-135px text-end"></th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50px border me-5">
                          <img
                            src={IMAGES.NilePerchFisheriesLtd}
                            className="object-fit-contain"
                            alt=""
                          />
                        </div>
                        <div className="d-flex flex-column">
                          <span className="fs-15 fw-600">
                            Nutro Wafer Biscuits
                          </span>
                          <span className="fs-14 fw-500">
                            Wadsworth Distributors Limited
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="fs-20 fw-600">-</span>
                    </td>
                    <td>
                      <span className="fs-20 fw-600">-</span>
                    </td>
                    <td className="fs-16 fw-600 text-end">
                      <Button
                        variant="link"
                        className="btn-flush text-gray text-hover-primary"
                      >
                        2 variations
                      </Button>
                    </td>
                  </tr>
                  <tr className="table-active">
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50px border me-5">
                          <img
                            src={IMAGES.NilePerchFisheriesLtd}
                            className="object-fit-contain"
                            alt=""
                          />
                        </div>
                        <div className="d-flex flex-column">
                          <span className="fs-15 fw-600">
                            Nutro Wafer Biscuits
                          </span>
                          <span className="fs-14 fw-500">
                            Wadsworth Distributors Limited
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="fs-15 fw-600">
                        100 Cartons,{' '}
                        <span className="text-danger">150 Dozens</span>
                      </span>
                    </td>
                    <td>
                      <Form.Control
                        className="form-control form-control-custom bg-white min-h-40px mw-130px"
                        value="3 months"
                      />
                    </td>
                    <td className="text-end">
                      <Button
                        variant="primary"
                        className="btn-sm min-h-40px fs-14 fw-600"
                        onClick={() =>
                          navigate(
                            '/inventory/goods-in-warehouse/product-details'
                          )
                        }
                      >
                        View details
                      </Button>
                    </td>
                  </tr>

                  <tr className="table-active">
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50px border me-5">
                          <img
                            src={IMAGES.NilePerchFisheriesLtd}
                            className="object-fit-contain"
                            alt=""
                          />
                        </div>
                        <div className="d-flex flex-column">
                          <span className="fs-15 fw-600">
                            Nutro Wafer Biscuits
                          </span>
                          <span className="fs-14 fw-500">
                            Wadsworth Distributors Limited
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="fs-15 fw-600">
                        100 Cartons, 150 Dozens
                      </span>
                    </td>
                    <td>
                      <Form.Control
                        className="form-control form-control-custom bg-white min-h-40px mw-130px"
                        value="3 months"
                      />
                    </td>
                    <td className="text-end">
                      <Button
                        variant="primary"
                        className="btn-sm min-h-40px fs-14 fw-600"
                        onClick={() =>
                          navigate(
                            '/inventory/goods-in-warehouse/product-details'
                          )
                        }
                      >
                        View details
                      </Button>
                    </td>
                  </tr>

                  <tr className="table-active">
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50px border me-5">
                          <img
                            src={IMAGES.NilePerchFisheriesLtd}
                            className="object-fit-contain"
                            alt=""
                          />
                        </div>
                        <div className="d-flex flex-column">
                          <span className="fs-15 fw-600">
                            Nutro Wafer Biscuits
                          </span>
                          <span className="fs-14 fw-500">
                            Wadsworth Distributors Limited
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="fs-15 fw-600">
                        100 Cartons, 150 Dozens
                      </span>
                    </td>
                    <td>
                      <Form.Control
                        className="form-control form-control-custom bg-white min-h-40px mw-130px"
                        value="3 months"
                      />
                    </td>
                    <td className="text-end">
                      <Button
                        variant="primary"
                        className="btn-sm min-h-40px fs-14 fw-600"
                        onClick={() =>
                          navigate(
                            '/inventory/goods-in-warehouse/product-details'
                          )
                        }
                      >
                        View details
                      </Button>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50px position-relative border me-5">
                          <img
                            src={IMAGES.NilePerchFisheriesLtd}
                            className="object-fit-contain"
                            alt=""
                          />
                          <div className="position-absolute start-0 bottom-0 m-n2">
                            <img
                              className="error-icon"
                              src={IMAGES.YellowError}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <span className="fs-15 fw-600">
                            Nutro Wafer Biscuits
                          </span>
                          <span className="fs-14 fw-500">
                            Wadsworth Distributors Limited
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="fs-15 fw-600 text-danger">
                        79 Pieces
                      </span>
                    </td>
                    <td>
                      <Form.Control
                        className="form-control form-control-custom bg-white min-h-40px mw-130px"
                        value="3 months"
                      />
                    </td>
                    <td className="text-end">
                      <Button
                        variant="primary"
                        className="btn-sm min-h-40px fs-14 fw-600"
                        onClick={() =>
                          navigate(
                            '/inventory/goods-in-warehouse/product-details'
                          )
                        }
                      >
                        View details
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default DiscountedProducts;
