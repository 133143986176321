import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CrossSvg from '../../_admin/assets/media/close.png';
import clsx from 'clsx';
const RejectProduct = (props: any) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-lg-590px"
        className="border-r10px"
        contentClassName="p-5"
      >
        <Modal.Header className="border-bottom-0 text-center pb-6 mx-auto">
          <div className="symbol symbol-md-40px symbol-35px close-inner-top">
            <img
              width={40}
              height={40}
              src={CrossSvg}
              alt=""
              onClick={props.onHide}
            />
          </div>
          <Modal.Title className="fs-26 fw-bolder mw-lg-375px">
            Are you sure you want to reject product{' '}
            {props.productDetails.title.replace(/\s*\)\s*/g, ')')}?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="text-center fs-18 fw-500 mb-6">
            The seller won’t be able to sell this product on the platform.
          </div>
          <Form.Group>
            <Form.Control
              className={clsx(
                props.validation
                  ? 'border border-danger'
                  : 'form-control-custom'
              )}
              as="textarea"
              rows={5}
              value={props.message}
              placeholder="Please type the the reason…"
              onChange={(event: any) => {
                props.handleChange(event.target.value.trimStart());
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="justify-content-center pt-1 border-top-0">
          <Button
            variant="danger"
            size="lg"
            onClick={() => {
              props.handleProductReject();
            }}
          >
            {props.loading ? (
              <span
                className="indicator-progress"
                style={{ display: 'block' }}
              >
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              'Yes, Reject this product'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default RejectProduct;
