/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// Media'
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { success } from '../../../../Global/toast';
import Password from '../../../../_admin/assets/media/auth/password.png';
import ShowPassword from '../../../../_admin/assets/media/auth/showPassword.png';
import BrandLogo from '../../../../_admin/assets/media/uTradeLogo.png';
import APICallService from '../../../../api/apiCallService';
import { AUTH } from '../../../../api/apiEndPoints';
import { APIJSON } from '../../../../api/apiJSON/auth';
import { auth } from '../../../../utils/toast';
import { useAuth } from '../core/Auth';
const loginSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, 'Length should be minimum 6')
    .max(15, 'Length should be maximum 15')
    .required('Password is required'),
});
const initialValues = {
  newPassword: '',
};
const CreatePassword = () => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const { saveAuth, saveCurrentUser } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.height = '100%';
    }
    return () => {
      if (root) {
        root.style.height = 'auto';
      }
    };
  }, []);
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        if (location.state) {
          let data = {
            ...location.state,
            newPassword: values.newPassword,
          };
          let apiService = new APICallService(
            AUTH.RESETPASSWORD,
            APIJSON.resetPassword(data)
          );
          let response = await apiService.callAPI();
          // if (response) {
          //   saveAuth(response.token);
          //   let user = response.user;
          //   saveCurrentUser(user);
          //   success(auth.passwordReset);
          // }
          if (response) {
            // saveAuth(response.token);
            // let user: any = response.user;
            // for (var i = 0; i < user.districts.length; i++) {
            //   user.districts[i].title = user.districts[i].name;
            //   if (i === 0) {
            //     user.districts[i].flag = true;
            //   } else {
            //     user.districts[i].flag = false;
            //   }
            // }
            // saveCurrentUser(user);
            success(auth.passwordReset);
            navigate("/auth/login");
          }
        }
      } catch (error) {
        setStatus('The registration details is incorrect');
      }
      setSubmitting(false);
      setLoading(false);
    },
  });
  return (
    <div className="d-flex flex-column flex-root bg-white">
      <div className="d-flex flex-column flex-lg-row-fluid py-10">
        <div className="">
          <Link
            to="/"
            className="mb-12 position-absolute top-0 start-0 mt-10 ms-10"
          >
            <img
              alt="Logo"
              src={BrandLogo}
              className="h-40px"
            />
          </Link>
        </div>
        <div className="d-flex flex-center pt-lg-0 pt-15 flex-column flex-column-fluid">
          <div className="w-lg-475px p-8 p-md-10 p-lg-15 mx-auto">
            <form
              className="form w-100"
              onSubmit={formik.handleSubmit}
            >
              <div className="mb-6 text-center">
                <h1 className="text-dark fs-35 mb-3 fw-bolder">
                  Create new password
                </h1>
                <div className="text-dark fs-16 fw-500">
                  Please set a new password for your account.
                  <br></br>
                </div>
              </div>
              <div className="d-flex flex-wrap mb-2 justify-content-center">
                <div className="d-flex flex-wrap">
                  <div className="input-group input-group-solid h-60px border-0 border-r8px bg-light w-375px">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="password"
                      {...formik.getFieldProps('newPassword')}
                      className={clsx(
                        'form-control form-control-lg border-0 bg-light text-dark fs-16 fw-500'
                      )}
                      value={formik.values.newPassword.trimStart()}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      name="newPassword"
                    />
                    <span
                      className="input-group-text fs-16 fw-500 text-dark bg-light border-0 "
                      id="basic-addon1"
                    >
                      {showPassword ? (
                        <img
                          height={15}
                          width={25}
                          src={ShowPassword}
                          alt=""
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        ></img>
                      ) : (
                        <img
                          height={15}
                          width={25}
                          src={Password}
                          alt=""
                          onClick={() => {
                            formik.values.newPassword.trimStart()
                              ? setShowPassword(!showPassword)
                              : setShowPassword(false);
                          }}
                        ></img>
                      )}
                    </span>
                  </div>
                  {formik.touched.newPassword && formik.errors.newPassword && (
                    <div className="fv-plugins-message-container">
                      <span
                        role="alert"
                        className="text-danger fs-14 fw-500"
                      >
                        {formik.errors.newPassword}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="text-center mt-5">
                <button
                  type="submit"
                  id="kt_sign_in_submit"
                  className="btn btn-primary br-8 w-lg-375px h-60px"
                  disabled={!formik.values.newPassword.trimStart()}
                >
                  {loading ? (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  ) : (
                    <span className="indicator-label fs-16 fw-bolder">
                      Set password & Sign in
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
          <div className="d-flex flex-center fs-16 fw-normal">
            Go back to
            <Link
              to="/auth/login"
              className="text-primary fs-16 fw-bold"
            >
              &nbsp;Sign in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export { CreatePassword };
