/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { Profile } from '../../../../../utils/string';
import { CustomSelect } from '../../../../custom/Select/CustomSelect';
import { seller } from '../../../../../api/apiEndPoints';
import APICallService from '../../../../../api/apiCallService';
import { useEffect, useState } from 'react';
import AutoComplete from '../../../../custom/autoComplete';
import { AllSeller } from '../../../../../utils/constants';
const EditBusinessDetails = (props: any) => {
  const [requiredCategories, setRequiredCategories] = useState<any>([]);
  useEffect(() => {
    (async () => {
      await checkCategory();
    })();
  }, []);
  const checkCategory = async () => {
    let params: any = {};
    props.profileData.business.categories.map((val: any, index: number) => {
      params = { ...params, ['categories[' + index + ']']: val.reference._id };
    });
    let apiService = new APICallService(seller.checkCategories, params, {
      id: props.profileData.business._id,
    },'','','',AllSeller);
    let response = await apiService.callAPI();
    if (response) {
      setRequiredCategories(response);
      // props.onRequiredCategories
    }
  };
  const handleSelectChange = (selectedOptions: any, actionMeta: any) => {
    if (actionMeta.action === 'remove-value') {
      const removedOption = actionMeta.removedValue;
      if (requiredCategories.includes(removedOption._id)) {
        return;
      }
    }
    // props.onCategorySelect(selectedOptions, actionMeta);
  };
  return (
    <>
      <div className="card-body bg-light pt-9 pb-0">
        <Row className="align-items-center kyc-process border-0">
          <Col
            lg={6}
            className="mb-5"
          >
            <Row className="align-items-center">
              <Col lg={4}>
                <label className=" fs-16 fw-500 text-dark">
                  {Profile.name}
                </label>
              </Col>
              <Col>
                <input
                  placeholder="Email or mobile phone number"
                  // {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-custom text-dark bg-white'
                    //   {'is-invalid': formik.touched.email && formik.errors.email},
                    //   {
                    //     'is-valid': formik.touched.email && !formik.errors.email,
                    //   }
                  )}
                  value={props.editData.business.ownerName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    props.onDetailsChange(e.target.name, e.target.value)
                  }
                  type="text"
                  name="ownerName"
                  autoComplete="off"
                />
              </Col>
            </Row>
          </Col>
          <Col
            lg={6}
            className="mb-5"
          >
            <Row className="align-items-center">
              <Col lg={4}>
                <label className=" fs-16 fw-500 text-dark">
                  {Profile.businessName}
                </label>
              </Col>
              <Col>
                <input
                  placeholder="Email or mobile phone number"
                  // {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-custom text-dark bg-white'
                    //   {'is-invalid': formik.touched.email && formik.errors.email},
                    //   {
                    //     'is-valid': formik.touched.email && !formik.errors.email,
                    //   }
                  )}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    props.onDetailsChange(e.target.name, e.target.value)
                  }
                  value={props.editData.business.name}
                  type="text"
                  name="name"
                  autoComplete="off"
                />
              </Col>
            </Row>
          </Col>
          <Col
            lg={6}
            className="mb-5"
          >
            <Row className="align-items-center">
              <Col lg={4}>
                <label className=" fs-16 fw-500 text-dark">
                  {Profile.phoneNumber}
                </label>
              </Col>
              <Col>
                <input
                  placeholder="Email or mobile phone number"
                  // {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-custom text-dark bg-white'
                    //   {'is-invalid': formik.touched.email && formik.errors.email},
                    //   {
                    //     'is-valid': formik.touched.email && !formik.errors.email,
                    //   }
                  )}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    props.onDetailsChange(e.target.name, e.target.value)
                  }
                  disabled
                  value={props.editData.business.phone}
                  name="phone"
                  autoComplete="off"
                />
              </Col>
            </Row>
          </Col>
          <Col
            lg={6}
            className="mb-5"
          >
            <Row className="align-items-center">
              <Col lg={4}>
                <label className=" fs-16 fw-500 text-dark">
                  {Profile.email}
                </label>
              </Col>
              <Col>
                <input
                  placeholder="Email or mobile phone number"
                  // {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-custom text-dark bg-white'
                    //   {'is-invalid': formik.touched.email && formik.errors.email},
                    //   {
                    //     'is-valid': formik.touched.email && !formik.errors.email,
                    //   }
                  )}
                  disabled
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    props.onDetailsChange(e.target.name, e.target.value)
                  }
                  value={props.editData.business.email}
                  type="text"
                  name="email"
                  autoComplete="off"
                />
              </Col>
            </Row>
          </Col>
          <Col
            lg={12}
            className="mb-5"
          >
            <Row className="align-items-center">
              <Col lg={2}>
                <label className=" fs-16 fw-500 text-dark">
                  {Profile.businessAddress}
                </label>
              </Col>
              <Col>
                <AutoComplete
                  address={props.editData.business.address}
                  handleAddressChange={props.handleAddressChange}
                  lat={props.editData.business.lat}
                  lng={props.editData.business.lng}
                />
              </Col>
            </Row>
          </Col>
          <Col
            lg={12}
            className="mb-5"
          >
            <Row className="align-items-center">
              <Col lg={2}>
                <label className=" fs-16 fw-500 text-dark">
                  {Profile.aboutBusiness}
                </label>
              </Col>
              <Col>
                <textarea
                  placeholder="Email or mobile phone number"
                  // {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-custom text-dark bg-white'
                    //   {'is-invalid': formik.touched.email && formik.errors.email},
                    //   {
                    //     'is-valid': formik.touched.email && !formik.errors.email,
                    //   }
                  )}
                  onChange={(e): void =>
                    props.onDetailsChange(e.target.name, e.target.value)
                  }
                  value={props.editData.business.about}
                  name="about"
                  autoComplete="off"
                  rows={5}
                />
              </Col>
            </Row>
          </Col>
          <Col
            lg={12}
            className="mb-5"
          >
            <Row className="align-items-center">
              <Col lg={2}>
                <label className=" fs-16 fw-500 text-dark">
                  {Profile.businessCategory}
                </label>
              </Col>
              <></>
              <Col>
                {/* label: (
                              <>
                                <div className="symbol symbol-30px border me-3">
                                  <img
                                    src={val.image}
                                    className="object-fit-contain"
                                    alt=""
                                  />
                                </div>
                                <span className="fs-16 fw-600 text-black mb-0">
                                  {val.name}
                                </span>
                              </>
                            ),
                            value: val._id,
                            creationDate: val._createdAt, */}
                <CustomSelect
                  backgroundColor={'white'}
                  default={
                    props.editData.business.categories.length
                      ? props.editData.business.categories.map(
                          (catVal: any) => {
                            return {
                              value: catVal.reference.title,
                              label: catVal.reference.title,
                              title: catVal.reference.title,
                              _id: catVal.reference._id,
                              image: catVal.reference.image,
                            };
                          }
                        )
                      : []
                  }
                  options={
                    props.category.length
                      ? props.category.map((catVal: any) => {
                          return {
                            value: catVal.title,
                            label: catVal.title,
                            title: catVal.title,
                            _id: catVal._id,
                            image: catVal.image,
                          };
                        })
                      : []
                  }
                  onChange={(e: any, action: any) => {
                    props.onCategorySelect(e, action);
                  }}
                  isMulti={true}
                />
              </Col>
            </Row>
          </Col>
          <Col
            lg={12}
            className="mb-5"
          >
            <Row className="align-items-center">
              <Col lg={2}>
                <label className=" fs-16 fw-500 text-dark">
                  {Profile.businessType}
                </label>
              </Col>
              <Col>
                <CustomSelect
                  backgroundColor={'white'}
                  default={
                    props.editData.business.types.length
                      ? props.editData.business.types.map((val: any) => {
                          return {
                            value: val.reference.title,
                            label: val.reference.title,
                            title: val.reference.title,
                            _id: val.reference._id,
                            image: val.reference.image,
                          };
                        })
                      : []
                  }
                  options={
                    props.businessTypes?.length
                      ? props.businessTypes.map((val: any) => {
                          return {
                            value: val.title,
                            label: val.title,
                            title: val.title,
                            _id: val._id,
                            image: val.image,
                          };
                        })
                      : []
                  }
                  onChange={(e: any): void => props.onBusinessTypesSelect(e)}
                  isMulti={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};
export { EditBusinessDetails };
