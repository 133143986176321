import React, { useEffect, useState } from "react";
import { Button, Card, Col, FormLabel, Row } from "react-bootstrap";
import CustomDatePicker from "../../custom/DateRange/DatePicker";
import { CustomSelect } from "../../custom/Select/CustomSelect";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../Global/pagination";
import APICallService from "../../../api/apiCallService";
import { interTransferRequest } from "../../../api/apiEndPoints";
import {
  Add,
  AllModules,
  InterTransferUmart,
  ORDER_PAGE_LIMIT,
  PAGE_LIMIT,
} from "../../../utils/constants";
import Method from "../../../utils/methods";
import Loader from "../../../Global/loader";
import { getKey, setKey } from "../../../Global/history";
import { interTransferList } from "../../../utils/storeString";
import { useAuth } from "../auth";
const InterTransferRequest = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [requestData, setRequestData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState<any>(getKey(interTransferList.page) || 1);
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(interTransferList.limit) || PAGE_LIMIT
  );
  const [startDate, setStartDate] = useState<any>(
    getKey(interTransferList.filterStartDate)
      ? new Date(getKey(interTransferList.filterStartDate)?.toString() || "")
      : null
  );
  const [endDate, setEndDate] = useState<any>(
    getKey(interTransferList.filterEndDate)
      ? new Date(getKey(interTransferList.filterEndDate)?.toString() || "")
      : null
  );
  const [fetchLoader, setFetchLoader] = useState<boolean>(false);
  const fetchTransferRequests = async (
    page?: number,
    limit?: number,
    fromDate?: any,
    toDate?: any
  ) => {
    const params: any = {
      pageNo: page,
      limit: limit,
      needCount: true,
    };
    if (fromDate && toDate) {
      params.fromDate = Method.convertDateToFormat(fromDate, "YYYY-MM-DD");
      params.toDate = Method.convertDateToFormat(toDate, "YYYY-MM-DD");
    }
    setLoading(true);
    const apiService = new APICallService(
      interTransferRequest.listTransferRequest,
      params,
      "",
      "",
      "",
      "",
      InterTransferUmart
    );
    const response = await apiService.callAPI();
    if (response) {
      setRequestData(response.records);
      setTotalRecords(response.total);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (!Method.hasModulePermission(InterTransferUmart, currentUser)) {
      return window.history.back();
    }
    fetchTransferRequests(page, pageLimit, startDate, endDate);
  }, []);
  const handleDateFilter = async (event: any) => {
    setStartDate(event[0]);
    setEndDate(event[1]);
    setFetchLoader(true);
    setPage(1);
    setKey(interTransferList.filterStartDate, event[0], false);
    setKey(interTransferList.filterEndDate, event[1], false);
    setKey(interTransferList.page, 1);
    const startDateFormatted =
      event[0] && event[1]
        ? Method.convertDateToFormat(event[0], "YYYY-MM-DD")
        : "";
    const endDateFormatted =
      event[0] && event[1]
        ? Method.convertDateToFormat(event[1], "YYYY-MM-DD")
        : "";
    setTotalRecords(0);
    setRequestData([]);
    if (startDateFormatted && endDateFormatted) {
      await fetchTransferRequests(
        1,
        pageLimit,
        startDateFormatted,
        endDateFormatted
      );
    } else if (event[0] == null && event[1] == null) {
      await fetchTransferRequests(1, pageLimit);
    }
    setFetchLoader(false);
  };
  const handleCurrentPage = async (val: number) => {
    if (val === page || val.toString() === "...") return;
    setLoading(true);
    setPage(val);
    setKey(interTransferList.page, val);
    await fetchTransferRequests(val, pageLimit, startDate, endDate);
    setLoading(false);
  };
  const handleNextPage = async (val: number) => {
    setLoading(true);
    setPage(val + 1);
    setKey(interTransferList.page, val + 1);
    await fetchTransferRequests(val + 1, pageLimit, startDate, endDate);
    setLoading(false);
  };
  const handlePreviousPage = async (val: number) => {
    setLoading(true);
    setPage(val - 1);
    setKey(interTransferList.page, val - 1);
    await fetchTransferRequests(val - 1, pageLimit, startDate, endDate);
    setLoading(false);
  };
  const handlePageLimit = async (event: any) => {
    setLoading(true);
    setPage(1);
    setTotalRecords(0);
    setKey(interTransferList.page, 1);
    setKey(interTransferList.limit, parseInt(event.target.value));
    // setTests([]);
    await setPageLimit(parseInt(event.target.value));
    await fetchTransferRequests(1, event.target.value, startDate, endDate);
    setLoading(false);
  };
  console.log("requestData", requestData);
  const kgToTons = (kilograms: number) => {
    const conversionFactor = 1000;
    const tons = kilograms / conversionFactor;
    return tons.toFixed(3);
  };
  return (
    <div className="p-9">
      <Row className="align-items-center g-md-5 g-3 mb-7">
        <Col xs>
          <h1 className="fs-22 fw-bolder">Inter Transfer Request</h1>
        </Col>
        <Col md="auto">
          <div className="d-flex align-items-center">
            <FormLabel className="fs-16 fw-500">Filter by dates</FormLabel>
            <div className="ms-5">
              <CustomDatePicker
                className="form-control bg-white min-h-60px fs-16 fw-bold text-dark min-w-md-288px min-w-175px"
                onChange={handleDateFilter}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd/MM/yyyy"
                showFullMonthYearPicker={true}
                maxDate={new Date()}
                isClearable={true}
                inputTextBG="bg-white"
              />
            </div>
          </div>
        </Col>
        <Col md="auto">
        {Method.hasPermission(InterTransferUmart, Add, currentUser) && (
          <Button
            variant="primary"
            className="fs-16 fw-bold btn-lg"
            onClick={() => {
              navigate("/inter-transfer-request/create-transfer-request");
            }}
          >
            Create new request
          </Button>
        )}
        </Col>
      </Row>
      <Card className="border border-r10px">
        <Card.Body className="p-0">
          <div className="table-responsive">
            <table className="table table-rounded table-row-bordered align-middle gs-7 gy-6 mb-0">
              <thead>
                <tr className="fs-16 fw-bold text-dark border-bottom h-70px align-middle">
                  <th className="min-w-125px">Transfer Date & ID</th>
                  <th className="min-w-100px">Quantity</th>
                  <th className="min-w-100px">Weight</th>
                  <th className="min-w-100px">Total amount</th>
                  <th className="min-w-125px text-end"></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={5}>
                      <div className="d-flex justify-content-center">
                        <Loader loading={loading} />
                      </div>
                    </td>
                  </tr>
                ) : requestData.length === 0 ? (
                  <tr>
                    <td
                      colSpan={5}
                      className="text-center py-4"
                    >
                      <span className="fs-15 fw-bold text-black">
                        No data available
                      </span>
                    </td>
                  </tr>
                ) : (
                  requestData.map((val: any, index: number) => {
                    const totalQuantity = val.totalQuantities.reduce(
                      (sum: number, item: any) => sum + (item.stockCount || 0),
                      0
                    );
                    const totalWeight = val.totalQuantities.reduce(
                      (sum: number, item: any) => sum + (item.weight || 0),
                      0
                    );
                    return (
                      <tr key={index}>
                        <td className="fs-15 fw-500">
                          <div className="d-flex flex-column">
                            <span className="text-dark d-block">
                              {Method.convertDateToDDMMYYYYHOURS(
                                val._createdAt
                              )}
                            </span>
                            <span className="text-gray d-block">
                              {val.refKey}
                            </span>
                          </div>
                        </td>
                        <td>
                          <span className="fs-15 fw-500">
                            {totalQuantity}{" "}
                            {totalQuantity === 1 ? "item" : "items"}
                          </span>
                        </td>
                        <td>
                          <span className="fs-15 fw-500">
                            {kgToTons(totalWeight) + " tons"}
                          </span>
                        </td>
                        <td>
                          <span className="fs-15 fw-500">
                            {"TSh " +
                              Method.getGeneralizedAmount(
                                val.payment.totalCharge
                              )}
                          </span>
                        </td>
                        <td className="text-end">
                          <Button
                            variant="primary"
                            onClick={() => {
                              navigate(
                                "/inter-transfer-request/inter-transfer-detail",
                                {
                                  state: { id: val._id },
                                }
                              );
                            }}
                          >
                            View details
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
      <Pagination
        totalRecords={totalRecords}
        currentPage={page}
        handleCurrentPage={handleCurrentPage}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        handlePageLimit={handlePageLimit}
        pageLimit={pageLimit}
      />
    </div>
  );
};
export default InterTransferRequest;
