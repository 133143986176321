import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PageTitle } from "../../../_admin/layout/core";

import PlatformFees from "./platform-fees";

const index = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="*" element={<Navigate to="/error/404" />} />

        <Route index element={<Navigate to="/platform-fees/fees" />} />

        <Route
          path="fees"
          element={
            <>
              <PageTitle>Platform fees</PageTitle>
              <PlatformFees />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default index;
