import { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { KTSVG } from '../../../_admin/helpers';
import { CustomSelect } from '../../custom/Select/CustomSelect';
import p1 from '../../../_admin/assets/media/product/nutro-wafer-biscuits.png';
import p2 from '../../../_admin/assets/media/product/amazon-roll-gum.png';
import p3 from '../../../_admin/assets/media/product/milkit-pop-chocolates.png';
const SellingProducts = () => {
  const navigate = useNavigate();
  const [filterProducts, setFilterProducts] = useState([
    {
      value: 'Food',
      name: 'Food',
      label: (
        <>
          <div className="form-check form-check-custom form-check-solid form-check-lg">
            <input
              className="form-check-input me-4"
              type="checkbox"
              value=""
              id="Ex1"
            />
            <div className="symbol symbol-40px symbol-circle border me-2">
              <img
                src={p1}
                className="object-fit-contain"
                alt=""
              />
            </div>
            <label
              className="form-check-label fs-16 fw-600 text-dark"
              htmlFor="Ex1"
            >
              Food
            </label>
          </div>
        </>
      ),
    },
    {
      value: 'Food',
      name: 'Food',
      label: (
        <>
          <div className="form-check form-check-custom form-check-solid form-check-lg">
            <input
              className="form-check-input me-4"
              type="checkbox"
              value=""
              id="Ex2"
            />
            <div className="symbol symbol-40px symbol-circle border me-2">
              <img
                src={p2}
                className="object-fit-contain"
                alt=""
              />
            </div>
            <label
              className="form-check-label fs-16 fw-600 text-dark"
              htmlFor="Ex2"
            >
              Grocery & Gourmet Food
            </label>
          </div>
        </>
      ),
    },
  ]);
  return (
    <>
      <div className="p-9">
        <Row className="g-5">
          <Col xs={12}>
            <h1 className="fs-22 fw-bolder">Selling products</h1>
          </Col>
          <Col xs={12}>
            <Card className="bg-light border">
              <Card.Body className="px-7">
                <Row className="align-items-center">
                  <Col
                    md
                    className="mb-md-0 mb-5"
                  >
                    <div className="d-flex align-items-center min-w-md-317px w-fit-content position-relative me-lg-4">
                      <KTSVG
                        path="/media/icons/duotune/general/gen021.svg"
                        className="svg-icon-3 position-absolute ps-5"
                      />
                      <input
                        type="text"
                        id="kt_filter_search"
                        className="form-control form-control-custom bg-white min-h-60px ps-13 text-dark"
                        placeholder="Search by product name…"
                      />
                    </div>
                  </Col>
                  <Col md="auto">
                    <div className="d-flex align-items-center">
                      <label
                        htmlFor=""
                        className="fs-16 fw-500"
                      >
                        Filter products
                      </label>
                      <div className="min-w-lg-300px ms-6">
                        <CustomSelect
                          backgroundColor="#ffff"
                          value={[
                            {
                              value: 'optionOne',
                              name: 'optionOne',
                              label: (
                                <>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-40px symbol-circle me-3">
                                      <div className="symbol-label fs-20 fw-600 bg-light-primary text-primary">
                                        6
                                      </div>
                                    </div>
                                    <h6 className="fs-16 fw-600 mb-0">
                                      All categories
                                    </h6>
                                  </div>
                                </>
                              ),
                            },
                          ]}
                          isSearchable={false}
                          options={filterProducts}
                          isMulti={false}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12}>
            <Card className="border border-r10px">
              <Card.Body className="p-0">
                <div className="table-responsive">
                  <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4">
                    <thead>
                      <tr className="fs-16 fw-600 border-bottom align-middle">
                        <th className="min-w-225px">Product name</th>
                        <th className="min-w-200px">Unit sold</th>
                        <th className="min-w-125px">
                          Total <br /> customers
                        </th>
                        <th className="min-w-125px">Total sales</th>
                        <th className="min-w-150px text-end"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-50px border me-3">
                              <img
                                src={p1}
                                className="object-fit-contain"
                                alt=""
                              />
                            </div>
                            <div className="d-flex justify-content-start flex-column">
                              <span className="fw-600 fs-15">
                                Nutro Wafer Biscuits (Chocolate, 75g)
                              </span>
                              <span className="fs-14 fw-semibold d-block ">
                                Wadsworth Distributors Limited
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="badge badge-light border-r23px">
                            <span className="fs-15 fw-600 text-dark p-3 d-block">
                              100 C, 150 D, 200 P
                            </span>
                          </div>
                        </td>
                        <td>
                          <span className="fs-15 fw-600">22</span>
                        </td>
                        <td>
                          <span className="fs-15 fw-600">TSh 1,000,000</span>
                        </td>
                        <td className="text-end">
                          <Button
                            size="sm"
                            className="btn-table"
                          >
                            View details
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-50px border me-3">
                              <img
                                src={p2}
                                className="object-fit-contain"
                                alt=""
                              />
                            </div>
                            <div className="d-flex justify-content-start flex-column">
                              <span className="fw-600 fs-15">
                                Amazon Roll Gum
                              </span>
                              <span className="fs-14 fw-semibold d-block ">
                                Super Meals Limited
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="badge badge-light border-r23px">
                            <span className="fs-15 fw-600 text-dark p-3 d-block">
                              70 C, 80 D, 20 P
                            </span>
                          </div>
                        </td>
                        <td>
                          <span className="fs-15 fw-600">17</span>
                        </td>
                        <td>
                          <span className="fs-15 fw-600">TSh 1,000,000</span>
                        </td>
                        <td className="text-end">
                          <Button
                            size="sm"
                            className="btn-table"
                          >
                            View details
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-50px border me-3">
                              <img
                                src={p3}
                                className="object-fit-contain"
                                alt=""
                              />
                            </div>
                            <div className="d-flex justify-content-start flex-column">
                              <span className="fw-600 fs-15">
                                Milkit Pop Chocolates
                              </span>
                              <span className="fs-14 fw-semibold d-block ">
                                Kilimo Fresh Foods Africa LTD
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="badge badge-light border-r23px">
                            <span className="fs-15 fw-600 text-dark p-3 d-block">
                              50 D, 120 P
                            </span>
                          </div>
                        </td>
                        <td>
                          <span className="fs-15 fw-600">11</span>
                        </td>
                        <td>
                          <span className="fs-15 fw-600">TSh 1,000,000</span>
                        </td>
                        <td className="text-end">
                          <Button
                            size="sm"
                            className="btn-table"
                          >
                            View details
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default SellingProducts;
