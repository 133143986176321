import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { KTSVG } from '../../../../../_admin/helpers';
import { CustomComponentSelect } from '../../../../custom/Select/CustomComponentSelect';
import { CustomSelect } from '../../../../custom/Select/CustomSelect';
import { Link, useNavigate } from 'react-router-dom';
import { BannerIMAGES, IMAGES } from '../../../../../utils/dummyJSON';
import MarkPaid from '../../../../modals/mark-paid';
import { IGetAdvertisement } from '../../../../../types/responseIndex';
import verify from '../../../../../_admin/assets/media/svg_uTrade/verified.svg';
import {
  Add,
  AdvertisementCompleted,
  AdvertisementOngoing,
  AdvertisementPaid,
  AdvertisementRequest,
  AdvertisementUnpaid,
  AdvertisementUpcoming,
  Advertising,
  AllSeller,
  Cash,
  Edit,
  HomePage,
  PAGE_LIMIT,
  View,
} from '../../../../../utils/constants';
import APICallService from '../../../../../api/apiCallService';
import { advertisements, seller } from '../../../../../api/apiEndPoints';
import { advertisementJSON } from '../../../../../api/apiJSON/advertisement';
import { advertisementStatusJSON } from '../../../../../utils/staticJSON';
import Method from '../../../../../utils/methods';
import AdvertisementDetails from '../../../../modals/advertisement-details';
import Pagination from '../../../../../Global/pagination';
import CustomDatePicker from '../../../../custom/DateRange/DatePicker';
import Loader from '../../../../../Global/loader';
import { getKey, setKey } from '../../../../../Global/history';
import { listSellerAdvertisement } from '../../../../../utils/storeString';
import { useAuth } from '../../../auth';
import PermissionModal from '../../../../modals/permission';
const TabsAdvertisements = (props: any) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [markPaidModal, setMarkPaidModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [active, setActive] = useState('0');
  const [fetchLoading, setFetchLoading] = useState(false);
  const [advertisement, setAdvertisement] = useState<IGetAdvertisement | any>(
    []
  );
  const [showAdDetails, setShowAdDetails] = useState(false);
  const [startDate, setStartDate] = useState<any>(
    getKey(listSellerAdvertisement.filterStartDate)
    ? new Date(getKey(listSellerAdvertisement.filterStartDate)?.toString() || '')
    : null
  );
  const [endDate, setEndDate] = useState<any>(
    getKey(listSellerAdvertisement.filterEndDate)
    ? new Date(getKey(listSellerAdvertisement.filterEndDate)?.toString() || '')
    : null
  );
  const [tabKey, setTabKey] = useState<any>(1);
  const [search, setSearch] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState<any>(
    getKey(listSellerAdvertisement.page) || 1
  );
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listSellerAdvertisement.limit) || PAGE_LIMIT
  );
  const [advertisementState, setAdvertisementState] = useState<any>(
    getKey(listSellerAdvertisement.filterStatus) || advertisementStatusJSON[0]
  );
  const [showPermissionModal, setShowPermissionModal] = useState(false);

  useEffect(() => {
    (async () => {
      console.log('hii',getKey(listSellerAdvertisement.filterStatus),advertisementState)
      setTabKey(advertisementState? advertisementState.value:advertisementStatusJSON[0].value)
      await fetchAdvertisementData(
        1,
        pageLimit,
        advertisementState? advertisementState.value :advertisementStatusJSON[0].value,
        search,
        startDate,
        endDate
      );
    })();
  }, []);
  const fetchAdvertisementData = async (
    pageNo: number,
    limit: number,
    listType: number,
    searchTerm?: string,
    fromDate?: string,
    toDate?: string
  ) => {
    setFetchLoading(true);
    let params = {
      pageNo: pageNo,
      limit: limit,
      sortKey: 'name',
      sortOrder: 1,
      searchTerm: searchTerm ? searchTerm : '',
      state: listType,
      fromDate: fromDate ? fromDate : '',
      toDate: toDate ? toDate : '',
    };
    console.log('hiii',props.businessId);
    let apiService = new APICallService(
      seller.listAdvertisement,
      advertisementJSON.listAdvertisement(params),
      {
        id: props.businessId,
      },'','','',AllSeller
    );
    let response = await apiService.callAPI();
    setTotalRecords(response.total);
    setAdvertisement(response.records);
    setFetchLoading(false);
  };
  useEffect(() => {
    let temp = { ...advertisementState };
    if (temp.value === AdvertisementRequest) {
      temp.label =
        'New request' + (totalRecords ? ' (' + totalRecords + ')' : '');
    }
    if (temp.value === AdvertisementOngoing) {
      temp.label = 'Ongoing' + (totalRecords ? ' (' + totalRecords + ')' : '');
    }
    if (temp.value === AdvertisementUpcoming) {
      temp.label = 'Upcoming' + (totalRecords ? ' (' + totalRecords + ')' : '');
    }
    if (temp.value === AdvertisementCompleted) {
      temp.label =
        'Completed' + (totalRecords ? ' (' + totalRecords + ')' : '');
    }
    setAdvertisementState(temp);
  }, [totalRecords]);
  const handleCurrentPage = async (val: number, currentTabValue: number) => {
    setPage(val);
    setKey(listSellerAdvertisement.page, val);
    await fetchAdvertisementData(val, pageLimit, currentTabValue, search, startDate, endDate);
  };
  const handleNextPage = async (val: number, currentTabValue: number) => {
    setPage(val + 1);
    setKey(listSellerAdvertisement.page, val + 1);
    await fetchAdvertisementData(val + 1, pageLimit, currentTabValue, search, startDate, endDate);
  };
  const handlePreviousPage = async (val: number, currentTabValue: number) => {
    setPage(val - 1);
    setKey(listSellerAdvertisement.page, val - 1);
    await fetchAdvertisementData(val - 1, pageLimit, currentTabValue, search, startDate, endDate);
  };
  const handlePageLimit = async (event: any, currentTabValue: number) => {
    setPage(1);
    await setPageLimit(parseInt(event.target.value));
    setKey(listSellerAdvertisement.page, 1);
    setKey(listSellerAdvertisement.limit, parseInt(event.target.value));
    await fetchAdvertisementData(
      1,
      event.target.value,
      currentTabValue,
      search,
      startDate, 
      endDate
    );
  };
  const handleSearch = async (event: any, currentTabValue: number) => {
    setSearch(event.target.value);
    await fetchAdvertisementData(
      1,
      pageLimit,
      currentTabValue,
      event.target.value,
      startDate, 
      endDate
    );
  };
  const handlePay = async (advertisementId: string, index: number) => {
    setLoading(true);
    let temp = [...advertisement];
    let apiService = new APICallService(
      advertisements.payment,
      advertisementJSON.payAdvertisement({ paymentMethod: Cash }),
      {
        id: advertisementId,
      },'','','',Advertising
    );
    let response: any = await apiService.callAPI();
    if (response) {
      temp[index].payment = {
        status: AdvertisementPaid,
        paymentMethod: Cash,
        completedByAdmin: true,
      };
    }
    setAdvertisement(temp);
    setLoading(false);
  };
  const handleDateFilter = async (event: any) => {
    setStartDate(event[0]);
    setEndDate(event[1]);
    setKey(listSellerAdvertisement.filterStartDate, event[0], false);
    setKey(listSellerAdvertisement.filterEndDate, event[1], false);
    setTotalRecords(0);
    setAdvertisement([]);
    setFetchLoading(true);
    setPage(1);
    const startDateFormatted =
      event[0] && event[1]
        ? Method.convertDateToFormat(event[0], 'YYYY-MM-DD')
        : '';
    const endDateFormatted =
      event[0] && event[1]
        ? Method.convertDateToFormat(event[1], 'YYYY-MM-DD')
        : '';
      console.log(tabKey);
    await fetchAdvertisementData(
      1,
      pageLimit,
      tabKey,
      '',
      startDateFormatted,
      endDateFormatted
    );
    setFetchLoading(false);
  };
  return (
    <>
    {showPermissionModal && (
        <PermissionModal
        show={showPermissionModal}
        onHide={() => setShowPermissionModal(false)}
        moduleName = {'advertisement'}
      />
      )}
      {/* 
            <MarkPaid
                show={markPaidModal}
                onHide={() => setMarkPaidModal(false)}
                ModuleName = {AllSeller}
            /> */}
      {showAdDetails ? (
        <AdvertisementDetails
          show={showAdDetails}
          advertisement={advertisement}
          onHide={() => setShowAdDetails(false)}
        />
      ) : (
        <></>
      )}
      <Col xs="12">
        <Card className="bg-light border">
          <Card.Body>
            <Row className="align-items-center">
              <Col xs>
                <div className="mw-md-305px">
                  <CustomSelect
                    backgroundColor="#ffff"
                    value={advertisementState}
                    defaultValue={advertisementState}
                    options={advertisementStatusJSON}
                    onChange={async (k: any) => {
                      if (k) {
                        setTabKey(k.value);
                        setStartDate(null);
                        setEndDate(null);
                        setTotalRecords(0);
                        setAdvertisement([]);
                        setLoading(true);
                        setPage(1);
                        switch (k.value) {
                          case AdvertisementRequest:
                            await fetchAdvertisementData(
                              1,
                              pageLimit,
                              AdvertisementRequest,
                              search,
                              startDate,
                              endDate
                          );
                            break;
                          case AdvertisementOngoing:
                            await fetchAdvertisementData(
                              1,
                              pageLimit,
                              AdvertisementOngoing,
                              search,
                              startDate,
                              endDate
                            );
                            break;
                          case AdvertisementUpcoming:
                            await fetchAdvertisementData(
                              1,
                              pageLimit,
                              AdvertisementUpcoming,
                              search,
                              startDate,
                              endDate
                            );
                            break;
                          case AdvertisementCompleted:
                            await fetchAdvertisementData(
                              1,
                              pageLimit,
                              AdvertisementCompleted,
                              search,
                              startDate,
                              endDate
                            );
                            break;
                          default:
                            break;
                        }
                        setAdvertisementState(k);
                        setKey(listSellerAdvertisement.filterStatus,k,false);
                        setLoading(false);
                      } else {
                        setStartDate(null);
                        setEndDate(null);
                        setTotalRecords(0);
                        setAdvertisement([]);
                        setLoading(true);
                        setPage(1);
                        await fetchAdvertisementData(1, pageLimit, 0,search,startDate,endDate);
                        setAdvertisementState({});
                        setKey(listSellerAdvertisement.filterStatus,{},false);
                        setLoading(false);
                      }
                    }}
                  />
                </div>
              </Col>
              <Col xs="auto">
                <div className="min-w-md-305px">
                  <CustomDatePicker
                    className="form-control bg-white min-h-60px fs-16 fw-bold text-dark min-w-md-288px min-w-175px"
                    onChange={handleDateFilter}
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd/MM/yyyy"
                    showFullMonthYearPicker={true}
                    maxDate={new Date()}
                    isClearable={true}
                    inputTextBG="bg-white"
                  />
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col xs="12">
        <Card>
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table className="table table-rounded table-row-bordered align-middle gy-4 gs-7 mb-0">
                <thead>
                  <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
                    <th className="min-w-130px">Banner</th>
                    {tabKey === AdvertisementOngoing ? (
                      <>
                        <th className="min-w-125px">Ad placement</th>
                        <th className="min-w-125px">Impression</th>
                        <th className="min-w-125px">Clicks</th>
                        <th className="min-w-125px">End date</th>
                      </>
                    ) : (
                      <></>
                    )}
                    {tabKey === AdvertisementRequest ||
                    tabKey === AdvertisementUpcoming ? (
                      <>
                        <th className="min-w-125px">Ad placement</th>
                        <th className="min-w-125px">Start date</th>
                        <th className="min-w-125px">Cost per day</th>
                      </>
                    ) : (
                      <></>
                    )}
                    {tabKey === AdvertisementCompleted ? (
                      <>
                        <th className="min-w-125px">Ended date</th>
                        <th className="min-w-125px">Impression</th>
                        <th className="min-w-125px">Clicks</th>
                        <th className="min-w-125px">Payment status</th>
                      </>
                    ) : (
                      <></>
                    )}
                    <th className="min-w-150px text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  {!fetchLoading ? (
                    <>
                      {advertisement && advertisement.length ? (
                        <>
                          {advertisement.map(
                            (advertiseVal: any, index: number) => {
                              return (
                                <tr>
                                  <td>
                                    <img
                                      className="img-fluid mw-87px mh-50px border-r6px object-fit-contain"
                                      src={advertiseVal.image}
                                      alt=""
                                    />
                                  </td>
                                  {tabKey === AdvertisementRequest ||
                                  tabKey === AdvertisementUpcoming ? (
                                    <>
                                      <td>
                                        <span className="fs-15 fw-500">
                                          {advertiseVal.placement === HomePage
                                            ? 'Home page'
                                            : 'Category page'}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fs-15 fw-500">
                                          {Method.convertDateToDDMMYYYY(
                                            advertiseVal.startDate
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fs-15 fw-500">
                                          TSh{' '}
                                          {Method.getGeneralizedAmount(
                                            advertiseVal.amount
                                          )}
                                        </span>
                                      </td>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {tabKey === AdvertisementOngoing ? (
                                    <>
                                      <td>
                                        <span className="fs-15 fw-500">
                                          {advertiseVal.placement === HomePage
                                            ? 'Home page'
                                            : 'Category page'}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fs-15 fw-500">
                                          {advertiseVal.impressions}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fs-15 fw-500">
                                          {advertiseVal.clicks}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fs-15 fw-500">
                                          {Method.convertDateToDDMMYYYY(
                                            advertiseVal.endDate
                                          )}
                                        </span>
                                      </td>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {tabKey === AdvertisementCompleted ? (
                                    <>
                                      <td>
                                        <span className="fs-15 fw-500">
                                          {Method.convertDateToDDMMYYYY(
                                            advertiseVal.endDate
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fs-15 fw-500">
                                          {advertiseVal.impressions}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fs-15 fw-500">
                                          {advertiseVal.clicks}
                                        </span>
                                      </td>
                                      <td>
                                        {advertiseVal.payment.status ===
                                        AdvertisementUnpaid ? (
                                          <>
                                          {Method.hasPermission(AllSeller, View, currentUser) && Method.hasPermission(Advertising, Add, currentUser) ? (
                                          <Button
                                            type="button"
                                            variant="light-primary"
                                            className="btn-table"
                                            onClick={() => {
                                              handlePay(
                                                advertiseVal._id,
                                                index
                                              );
                                            }}
                                          >
                                            {!loading && (
                                              <span className="indicator-label fs-16 fw-bolder">
                                                Mark as paid
                                              </span>
                                            )}
                                            {loading && (
                                              <span
                                                className="indicator-progress indicator-label fs-16 fw-600"
                                                style={{ display: 'block' }}
                                              >
                                                Please wait...{' '}
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                              </span>
                                            )}{' '}
                                          </Button>
                                          ) : (
                                            <></>
                                          )}
                                          </>
                                        ) : advertiseVal.payment
                                            .paymentMethod === Cash ? (
                                          <Button
                                            variant="white"
                                            className="btn-flush fs-15 fw-600"
                                          >
                                            Paid cash{' '}
                                            <img
                                              className="ms-1"
                                              width={18}
                                              height={18}
                                              src={verify}
                                              alt=""
                                            />
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="white"
                                            className="btn-flush fs-15 fw-600"
                                          >
                                            Paid online{' '}
                                            <img
                                              className="ms-1"
                                              width={18}
                                              height={18}
                                              src={verify}
                                              alt=""
                                            />
                                          </Button>
                                        )}
                                      </td>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <td className="text-end">
                                  {Method.hasPermission(AllSeller, View, currentUser) && Method.hasPermission(Advertising, View, currentUser) ? (
                                    <>
                                    {tabKey === AdvertisementRequest ? (
                                      <Button
                                        onClick={() => {
                                          setShowAdDetails(true);
                                          setAdvertisement(advertiseVal);
                                        }}
                                        className="btn-table"
                                      >
                                        View details
                                      </Button>
                                    ) : (
                                      <>
                                        {' '}
                                        <Button
                                          variant="primary"
                                          className="btn-table"
                                          onClick={() => {
                                            navigate(
                                              '/advertisement/view-advertisement/advertising-details',
                                              {
                                                state: {
                                                  status: tabKey,
                                                  id: advertiseVal._id,
                                                },
                                              }
                                            );
                                          }}
                                        >
                                          View details
                                        </Button>
                                      </>
                                    )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {Method.hasPermission(AllSeller, View, currentUser) && !Method.hasPermission(Advertising, View, currentUser) ? (
                                    <>
                                        <Button
                                          variant="primary"
                                          className="btn-table"
                                          onClick={() =>{setShowPermissionModal(true)}}
                                        >
                                          View details
                                        </Button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <>
                          <tr>
                            {tabKey === AdvertisementOngoing && (
                              <td colSpan={6}>
                                <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                  No Data found
                                </div>
                              </td>
                            )}
                            {(tabKey === AdvertisementRequest ||
                              tabKey === AdvertisementUpcoming) && (
                              <td colSpan={5}>
                                <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                  No Data found
                                </div>
                              </td>
                            )}
                            {tabKey === AdvertisementCompleted && (
                              <td colSpan={6}>
                                <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                  No Data found
                                </div>
                              </td>
                            )}
                          </tr>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <tr>
                        {tabKey === AdvertisementOngoing && (
                          <td colSpan={6}>
                            <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                              <Loader loading={fetchLoading} />
                            </div>
                          </td>
                        )}
                        {(tabKey === AdvertisementRequest ||
                          tabKey === AdvertisementUpcoming) && (
                          <td colSpan={5}>
                            <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                              <Loader loading={fetchLoading} />
                            </div>
                          </td>
                        )}
                        {tabKey === AdvertisementCompleted && (
                          <td colSpan={6}>
                            <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                              <Loader loading={fetchLoading} />
                            </div>
                          </td>
                        )}
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
        {fetchLoading ? (
          <></>
        ) : (
          <>
            {advertisement.length ? (
              <>
                <Pagination
                  totalRecords={totalRecords}
                  currentPage={page}
                  handleCurrentPage={(event: any) => {
                    handleCurrentPage(event, tabKey);
                  }}
                  handleNextPage={(event: any) => {
                    handleNextPage(event, tabKey);
                  }}
                  handlePreviousPage={(event: any) => {
                    handlePreviousPage(event, tabKey);
                  }}
                  pageLimit={pageLimit}
                  handlePageLimit={(event: any) => {
                    handlePageLimit(event, tabKey);
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </Col>
    </>
  );
};
export default TabsAdvertisements;
