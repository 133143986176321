import {
  IGetSellerReport,
  IAllProductReport,
  IProductVariantReport,
  IInventoryReport,
} from '../../types';
import Method from '../../utils/methods';
export const reportJson = {
  getReportWithKey: (
    { data, fromDate, toDate, utcOffset }: IGetSellerReport,
    key: string
  ) => {
    let params: any = {
      utcOffset: utcOffset,
    };
    if (fromDate && toDate) {
      params = {
        ...params,
        fromDate: Method.convertDateToFormat(fromDate, 'YYYY-MM-DD'),
        toDate: Method.convertDateToFormat(toDate, 'YYYY-MM-DD'),
      };
    }
    data.forEach((item: any, index: number) => {
      params[key + '[' + index + ']'] = item;
    });
    return params;
  },
  getAllProductReport: ({ businesses, utcOffset }: IAllProductReport) => {
    let params: any = {
      utcOffset: utcOffset,
    };
    businesses.forEach((item: any, index: number) => {
      params['businesses[' + index + ']'] = item;
    });
    return params;
  },
  getProductVariantReport: ({ variants, utcOffset }: IProductVariantReport) => {
    let params: any = {
      utcOffset: utcOffset,
    };
    variants.forEach((item: any, index: number) => {
      params['variants[' + index + ']'] = item;
    });
    return params;
  },
  getInventoryReport: ({ fromDate, toDate, utcOffset }: IInventoryReport) => {
    let params: any = {
      utcOffset: utcOffset,
    };
    if (fromDate && toDate) {
      params = {
        ...params,
        fromDate: Method.convertDateToFormat(fromDate, 'YYYY-MM-DD'),
        toDate: Method.convertDateToFormat(toDate, 'YYYY-MM-DD'),
      };
    }
    return params;
  },
};
