import {
  IAddMember,
  IAssignDistrict,
  IListAdmin,
  IListTeam,
  IUpdateStatus,
} from '../../types';
import { Manager, ServiceMember } from '../../utils/constants';
export const customerServiceJSON = {
  addMember: ({
    name,
    districts,
    email,
    imageReader,
    phoneCountry,
    phoneNumber,
    userType,
    role,
  }: IAddMember) => {
    let form = new FormData();
    form.append('name', name);
    form.append('email', email);
    form.append('phoneCountry', phoneCountry);
    form.append('phone', phoneNumber);
    if (role.length) {
      role.map((val: any, index: number) => {
        form.append('role[' + index + '][type]', val);
      });
    }
    if (districts && districts.length) {
      districts.map((val: any, index: number) => {
        form.append('districts[' + index + ']', val);
      });
    }
    form.append('image', imageReader);
    return form;
  },
  listTeam: ({
    pageNo,
    limit,
    sortKey,
    sortOrder,
    searchTerm,
    date,
  }: IListTeam) => {
    return {
      pageNo: pageNo,
      limit: limit,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: true,
      searchTerm: searchTerm ? searchTerm : '',
      date: date ? date : '',
    };
  },
  listAdmin: ({
    pageNo,
    limit,
    sortKey,
    sortOrder,
    searchTerm,
    role,
  }: IListAdmin) => {
    return {
      pageNo: pageNo,
      limit: limit,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: true,
      searchTerm: searchTerm ? searchTerm : '',
      role: role ? role : [Manager, ServiceMember],
    };
  },
  assignDistrict: ({ districts }: IAssignDistrict) => {
    return { districts: districts };
  },
  updateStatus: ({ active }: IUpdateStatus) => {
    return { active: active };
  },
};
