export const String = {
  PleaseWait: 'Please Wait',
  NoDataFound: 'No Data Found.',
  countryCode: '+255',
  EnterName: 'Enter name...',
  EnterPhone: 'Enter phone number...',
  EnterEmail: 'Enter email...',
};
export const Error = {
  NET_ERR: 'Please check your internet connection and try again!',
};
export const Dashboard = {
  businessDetailsReview: 'Business details in review!',
  stayTuned: 'Stay tuned! We’ll verify your details soon.',
  editKyc: 'Edit KYC details',
  welcome: 'Hello Wadsworth Distributors Limited',
  completeBusiness: 'Complete business verification!',
  addKyc:
    'Please add your KYC details to complete your business verification and start selling on Hustler.',
  completeKyc: 'Complete KYC',
  moreInformation: 'We require more information from you!',
  uploadClear:
    'Please upload clear picture of TIN Certificate and Business License.',
  congrats: 'Congratulations! Your business details are verified! 😀',
  startSelling: 'You can now start selling!',
  detailsRejected: 'Business details rejected',
  incorrectDocuments:
    'Reason: We identified that your seller account doesn’t comply with our terms and conditions. If you have any queries, please contact us.',
  contactus: 'Contact us',
};
export const contactUs = {
  title: 'Contact Us',
  message:
    'Please send us your feedback, suggestions or queries and we will get back to you by email soon.',
  sendMessage: 'Send Message',
};
export const General = {
  pleaseWait: 'Please wait...',
};
export const Profile = {
  title: 'Manage profile',
  name: 'Name: ',
  businessName: 'Business name:',
  phoneNumber: 'Phone number:',
  email: 'Email:',
  businessAddress: 'Business address:',
  aboutBusiness: 'About the business:',
  businessCategory: 'Business category:',
  businessType: 'Business type:',
  businessDetails: 'Business details',
  businessCertificate: 'Business certificates',
  tinCertificate: 'TIN Certificate',
  businessLicense: 'Business License',
  vatCertificate: 'VAT Certificate',
  foodCertificate: 'Food Category Certif…',
  hygienicCertificate: 'Hygiene Monitored Certif…',
};

export const CustomNotificationString={
  cutomNofication:'Custom Notification',
  title:'Title',
  description:'Description',
  selectCustomer:'Select Customers'
}

export const RolesString = {
  title: 'Roles & Permission',
  startAddingTitle: 'Start adding roles!',
  manageRoleBody: 'You can add role and manage them.',
  addRole: 'Add role',
  moduleName: 'Module name',
  permission: 'Permission',
  namePlaceholder: 'Enter role name',
  addNewRole: 'Add new role',
  roleName: 'Role name',
  setPermission: 'Set permissions',
  customer: 'Customer',
  ordersDelivery: 'Orders & delivery',
  allProduct: 'All products',
  inventory: 'Inventory',
  master: 'Master',
  cancel: 'Cancel',
  editRole: 'Edit role',
  deleteRole: 'Delete role',
  saveChanges: 'Save Changes',
  yesDelete: 'Yes, delete',
};

export const UserString = {
  title: 'User management',
  startAddingTitle: 'Start adding users!',
  manageRoleBody: 'You can add users and manage them.',
  addUser: 'Add user',
  addNewUsers: 'Add new users',
  addNewUser: 'Add new user',
  editUser: 'Edit user',
  typeHere: 'Type here…',
  cancel: 'Cancel',
  name: 'Name',
  phoneNumber: 'Phone number',
  userRole: 'User role',
  activate: 'Activate',
  deactivate: 'Deactivate',
  search: 'Search by user name...',
  edit: 'Edit user',
  delete: 'Delete user',
  email: 'Email',
  assignRole: 'Assign role',
  uploadPhoto: 'Upload photo',
  saveUser: 'Save details',
};