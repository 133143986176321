import { Button, Card, Col, Row } from 'react-bootstrap';
import { KTSVG } from '../../../../_admin/helpers';
import { CustomSelectWhite } from '../../../custom/Select/CustomSelectWhite';
import { useEffect, useState } from 'react';
import APICallService from '../../../../api/apiCallService';
import { buyer } from '../../../../api/apiEndPoints';
import Loader from '../../../../Global/loader';
import Method from '../../../../utils/methods';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../../Global/pagination';
import { listBuyerOrder } from '../../../../utils/storeString';
import {
  AllCustomers,
  OrderCancelled,
  OrderDelivered,
  OrderOutForDelivery,
  OrderProcessed,
  OrdersDelivery,
  OrderSubmitted,
  PAGE_LIMIT,
  View,
} from '../../../../utils/constants';
import { getKey, setKey } from '../../../../Global/history';
import CustomDatePicker from '../../../custom/DateRange/DatePicker';
import { CustomSelect } from '../../../custom/Select/CustomSelect';
import { orderStatusJSON } from '../../../../utils/staticJSON';
import PermissionModal from '../../../modals/permission';
import { useAuth } from '../../auth';
const TabsOrders = (props: any) => {
  const { currentUser } = useAuth();
  const [orders, setOrders] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<any>(
    getKey(listBuyerOrder.filterStartDate)
    ? new Date(getKey(listBuyerOrder.filterStartDate)?.toString() || '')
    : null
  );
  const [endDate, setEndDate] = useState<any>(
    getKey(listBuyerOrder.filterEndDate)
    ? new Date(getKey(listBuyerOrder.filterEndDate)?.toString() || '')
    : null
  );
  const [page, setPage] = useState<any>(getKey(listBuyerOrder.page) || 1);
  const [orderState, setOrderState] = useState<any>(getKey(listBuyerOrder.currentPlanStatus) || {});
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listBuyerOrder.limit) || PAGE_LIMIT
  );
  const [search, setSearch] = useState<string>(getKey(listBuyerOrder.search)?.toString() || '');
  const [tabKey, setTabKey] = useState<any>(
    getKey(listBuyerOrder.currentTab)
      ? () => {
          let temp: any = getKey(listBuyerOrder.currentTab);
          return parseInt(temp);
        }
      : 0
  );
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      await fetchInitData(page, pageLimit, 0, search,startDate,endDate);
    })();
  }, []);
  const fetchInitData = async (
    pageNo: number,
    limit: number,
    status?: any,
    search?: string,
    fromDate?: any,
    toDate?: any
  ) => {
    setFetchLoading(true);
    let params: any = {
      pageNo: pageNo,
      limit: limit,
      sortKey: '_createdAt',
      sortOrder: -1,
      needCount: true,
      searchTerm: search,
    };
    console.log('status', status);
    if (status.length > 0) {
      status.map((val: any) => {
        params = { ...params, listType: val };
      });
    }
    if (fromDate && toDate) {
      params = { ...params, fromDate: fromDate, toDate: toDate };
    }
    let id = props.customerId? props.customerId : localStorage.getItem('CustomerId');
    console.log('hii', id);
    let apiService = new APICallService(buyer.listOrder, params, { id: id },'','','',AllCustomers);
    let apiResponse = await apiService.callAPI();
    if (apiResponse) {
      setOrders(apiResponse.records);
      setTotalRecords(apiResponse.total);
    }
    setFetchLoading(false);
  };
  const getTabCategories = (tabIndex: number) => {
    switch (tabIndex) {
      case 1:
        return [1];
      case 2:
        return [2];
      case 3:
        return [3];
      case 4:
        return [4];
      case 5:
        return [5];
      case 6:
        return [7];
      default:
        return [];
    }
  };
  const handleCurrentPage = async (val: number) => {
    setFetchLoading(true);
    setPage(val);
    setKey(listBuyerOrder.page, val);
    // await fetchInitData(val, pageLimit, '');
    let selectedTabIndices: any = [];
    selectedTabIndices = [parseInt(tabKey)];
    for (const tabIndex of selectedTabIndices) {
      await fetchInitData(val, pageLimit, getTabCategories(tabIndex), search, startDate, endDate);
    }
    setFetchLoading(false);
  };
  const handleNextPage = async (val: number) => {
    setFetchLoading(true);
    setPage(val + 1);
    // await fetchInitData(val + 1, pageLimit, '');
    setKey(listBuyerOrder.page, val + 1);
    let selectedTabIndices: any = [];
    selectedTabIndices = [parseInt(tabKey)];
    for (const tabIndex of selectedTabIndices) {
      await fetchInitData(
        val + 1,
        pageLimit,
        getTabCategories(tabIndex),
        search,
        startDate,
        endDate
      );
    }
    setFetchLoading(false);
  };
  const handlePreviousPage = async (val: number) => {
    setFetchLoading(true);
    setPage(val - 1);
    setKey(listBuyerOrder.page, val - 1);
    let selectedTabIndices: any = [];
    selectedTabIndices = [parseInt(tabKey)];
    for (const tabIndex of selectedTabIndices) {
      await fetchInitData(
        val - 1,
        pageLimit,
        getTabCategories(tabIndex),
        search,
        startDate,
        endDate
      );
    }
    setFetchLoading(false);
  };
  const handlePageLimit = async (event: any) => {
    setFetchLoading(true);
    setPage(1);
    setKey(listBuyerOrder.page, 1);
    setKey(listBuyerOrder.limit, parseInt(event.target.value));
    await setPageLimit(parseInt(event.target.value));
    let selectedTabIndices: any = [];
    selectedTabIndices = [parseInt(tabKey)];
    for (const tabIndex of selectedTabIndices) {
      await fetchInitData(
        1,
        event.target.value,
        getTabCategories(tabIndex),
        search,
        startDate,
        endDate
      );
    }
    setFetchLoading(false);
  };
  const handleSearch = async (value: string) => {
    setSearch(value);
    setKey(listBuyerOrder.search, value, false);
    setFetchLoading(true);
    setPage(1);
    let selectedTabIndices: any = [];
    selectedTabIndices = [parseInt(tabKey)];
    for (const tabIndex of selectedTabIndices) {
      await fetchInitData(1, pageLimit, getTabCategories(tabIndex), value, startDate, endDate);
    }
    setFetchLoading(false);
  };
  const handleChange = async ([startDate, endDate]: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setKey(listBuyerOrder.filterStartDate, startDate, false);
    setKey(listBuyerOrder.filterEndDate, endDate, false);
    setFetchLoading(true);
    if (startDate && endDate) {
      let selectedTabIndices: any = [];
      selectedTabIndices = [parseInt(tabKey)];
      for (const tabIndex of selectedTabIndices) {
        await fetchInitData(
          1,
          pageLimit,
          getTabCategories(tabIndex),
          search,
          Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
          Method.convertDateToFormat(endDate, 'YYYY-MM-DD')
        );
      }
    } else {
      let selectedTabIndices: any = [];
      selectedTabIndices = [parseInt(tabKey)];
      for (const tabIndex of selectedTabIndices) {
        await fetchInitData(
          1,
          pageLimit,
          getTabCategories(tabIndex),
          search,
          null,
          null
        );
      }
    }
    setFetchLoading(false);
  };
  return (
    <>
    {showPermissionModal && (
        <PermissionModal
        show={showPermissionModal}
        onHide={() => setShowPermissionModal(false)}
        moduleName = {'orders & delivery'}
      />
      )}
      <Card className="bg-light border mb-6">
        <Card.Body className="px-7">
          <Row className="align-items-center">
            <Col className="d-flex d-flex justify-content-between">
              <Col
                md="auto"
                className="mb-md-0 mb-5"
              >
                <div className="d-flex align-items-center mw-lg-315px position-relative me-lg-4">
                  <KTSVG
                    path="/media/icons/duotune/general/gen021.svg"
                    className="svg-icon-3 position-absolute ms-4"
                  />
                  <input
                    type="text"
                    id="kt_filter_search"
                    className="form-control form-control-custom bg-white min-h-50px ps-12 text-dark h-50px"
                    placeholder="Search by order ID"
                    value={search}
                    onChange={(event: any) => {
                      handleSearch(event.target.value.trimStart());
                    }}
                  />
                </div>
              </Col>
              <Col md="auto">
                <div className="d-flex align-items-center">
                  <div className="card-toolbar">
                    <div className="ms-3">
                      <CustomSelect
                        backgroundColor="#ffff"
                        value={orderState}
                        defaultValue={orderState}
                        onChange={async (k: any) => {
                          if (k) {
                            setTabKey(k.value);
                            setTotalRecords(0);
                            setOrders([]);
                            setPage(1);
                            switch (k.value) {
                              case OrderSubmitted:
                                await fetchInitData(
                                  1,
                                  pageLimit,
                                  [OrderSubmitted],
                                  search,
                                  startDate,
                                  endDate
                                );
                                break;
                              case OrderProcessed:
                                await fetchInitData(
                                  1,
                                  pageLimit,
                                  [OrderProcessed],
                                  search,
                                  startDate,
                                  endDate
                                );
                                break;
                              case OrderOutForDelivery:
                                await fetchInitData(
                                  1,
                                  pageLimit,
                                  [OrderOutForDelivery],
                                  search,
                                  startDate,
                                  endDate
                                );
                                break;
                              case OrderDelivered:
                                await fetchInitData(
                                  1,
                                  pageLimit,
                                  [OrderDelivered],
                                  search,
                                  startDate,
                                  endDate
                                );
                                break;
                              case OrderCancelled:
                                await fetchInitData(
                                  1,
                                  pageLimit,
                                  [OrderCancelled],
                                  search,
                                  startDate,
                                  endDate
                                );
                                break;
                              default:
                                await fetchInitData(
                                  1,
                                  pageLimit,
                                  0,
                                  search,
                                  startDate,
                                  endDate
                                );
                                break;
                            }
                            setOrderState(k);
                            setKey(listBuyerOrder.currentPlanStatus, k, false);
                          } else {
                            setTotalRecords(0);
                            setOrders([]);
                            setPage(1);
                            await fetchInitData(
                              1,
                              pageLimit,
                              0,
                              search,
                              startDate,
                              endDate
                            );
                            setOrderState({});
                            setKey(listBuyerOrder.currentPlanStatus, {}, false);
                            setTabKey(0);
                          }
                        }}
                        options={orderStatusJSON}
                        isClearable={Object.keys(orderState).length}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="auto">
                <div className="d-flex align-items-center">
                  <div className="card-toolbar">
                    <div className="ms-3">
                      <CustomDatePicker
                        className="bg-white min-h-50px fs-16 fw-bold text-dark min-w-md-260px"
                        onChange={handleChange}
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        showFullMonthYearPicker={true}
                        maxDate={new Date()}
                        inputTextBG="bg-white"
                        isClearable={true}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="border border-r10px">
        <Card.Body className="p-0">
          <div className="table-responsive">
            <table className="table table-rounded table-row-bordered align-middle gs-7 gy-6 mb-0">
              <thead>
                <tr className="fw-bold fs-16 fw-600 text-dark border-bottom align-middle">
                  <th className="min-w-md-175px min-w-150px">
                    Order Date & ID
                  </th>
                  <th className="min-w-125px">Quantity</th>
                  <th className="min-w-125px">Total amount</th>
                  <th className="min-w-150px text-end"></th>
                </tr>
              </thead>
              <tbody className="fs-15 fw-600">
                {fetchLoading ? (
                  <>
                    <tr>
                      <td colSpan={4}>
                        <div className="d-flex justify-content-center">
                          <Loader loading={fetchLoading} />
                        </div>
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    {orders.length ? (
                      orders.map((orderVal: any) => {
                        return (
                          <>
                            <tr>
                              <td>
                                <span className="text-dark d-block">
                                  {Method.convertDateToDDMMYYYYHHMM(
                                    orderVal._createdAt,
                                    '-'
                                  )}
                                </span>
                                <span className="text-gray d-block">
                                  {' '}
                                  {orderVal.refKey}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {' '}
                                  {orderVal.orderedQuantities + ' items'}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {' '}
                                  {'TSh ' +
                                    Method.getGeneralizedAmount(
                                      orderVal.payment.totalCharge
                                    )}
                                </span>
                              </td>
                              <td className="text-end">
                              {(Method.hasPermission(AllCustomers, View, currentUser) && Method.hasPermission(OrdersDelivery, View, currentUser)) ? (
                                <Button
                                  className="fs-14 fw-600"
                                  onClick={() =>
                                    navigate('/orders/order-details', {
                                      state: {id: orderVal._id, moduleName: AllCustomers},
                                    })
                                  }
                                >
                                  View details
                                </Button>
                              ) : (
                                <></>
                              )}
                              {(Method.hasPermission(AllCustomers, View, currentUser) && !Method.hasPermission(OrdersDelivery, View, currentUser)) ? (
                                <Button
                                  className="fs-14 fw-600"
                                  onClick={() =>{setShowPermissionModal(true)}
                                  }
                                >
                                  View details
                                </Button>
                              ) : (
                                <></>
                              )}
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <>
                        {' '}
                        <td colSpan={4}>
                          <div className="d-flex justify-content-center">
                            <span className="fs-18 fw-500">No Data Found</span>
                          </div>
                        </td>
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
      {!fetchLoading && orders && orders.length ? (
        <Pagination
          totalRecords={totalRecords}
          currentPage={page}
          handleCurrentPage={handleCurrentPage}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          handlePageLimit={handlePageLimit}
          pageLimit={pageLimit}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default TabsOrders;
