import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CrossSvg from '../../_admin/assets/media/close.png';
import p1 from '../../_admin/assets/media/product/nutro.png';
import {
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  Piece,
} from '../../utils/constants';
import React from 'react';
import Method from '../../utils/methods';
const ReturnGoodsSellerModal = (props: any) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-lg-1000px min-w-md-700px"
        className="border-r10px"
        contentClassName="p-3"
      >
        <Modal.Header className="border-bottom-0 text-center pb-6 mx-auto">
          <div className="symbol symbol-md-40px symbol-35px close-inner-top-3">
            <img
              width={40}
              height={40}
              src={CrossSvg}
              alt=""
              onClick={props.onHide}
            />
          </div>
          {((props.expiring && props.expiring.quantityTypes.length) ||
            (props.expired && props.expired.quantityTypes.length)) && (
            <Modal.Title className="fs-26 fw-bolder">
              {props.expiring && props.expiring.quantityTypes.length ? (
                <>
                  <span className="text-warning fs-20">
                    {props.expiring.quantityTypes.map(
                      (quantVal: any, index: number) => (
                        <React.Fragment key={index}>
                          {index !== 0 ? ' , ' : ''}
                          {quantVal.stockCount}{' '}
                          {quantVal.type === CartonWithDozens ||
                          quantVal.type === CartonWithPieces
                            ? quantVal.type === CartonWithPieces
                              ? 'Cartons with pieces'
                              : 'Cartons with dozens'
                            : ''}
                          {quantVal.type === Dozen ? 'Dozens' : ''}
                          {quantVal.type === Piece ? 'Pieces' : ''}
                        </React.Fragment>
                      )
                    )}
                    <br className="br" />
                    are expiring in between{' '}
                    {Method.convertDateToDDMMYYYY(props.expiring.minDate)}-
                    {Method.convertDateToDDMMYYYY(props.expiring.maxDate)}.
                  </span>
                </>
              ) : (
                <></>
              )}
              <br />
              {props.expired && props.expired.quantityTypes.length ? (
                <>
                  <span className="text-danger fs-20">
                    {props.expired.quantityTypes.map(
                      (quantVal: any, index: number) => (
                        <React.Fragment key={index}>
                          {index !== 0 ? ' , ' : ''}
                          {quantVal.stockCount}{' '}
                          {quantVal.type === CartonWithDozens ||
                          quantVal.type === CartonWithPieces
                            ? quantVal.type === CartonWithPieces
                              ? 'Cartons with pieces'
                              : 'Cartons with dozens'
                            : ''}
                          {quantVal.type === Dozen ? 'Dozens' : ''}
                          {quantVal.type === Piece ? 'Pieces' : ''}
                        </React.Fragment>
                      )
                    )}
                    <br className="br" />
                    are expired in between{' '}
                    {Method.convertDateToDDMMYYYY(props.expired.minDate)}-
                    {Method.convertDateToDDMMYYYY(props.expired.maxDate)}.
                  </span>
                </>
              ) : (
                <></>
              )}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body className="pt-0 pb-5 overflow-x-auto h-500px">
          <Row className="justify-content-center  g-6">
            {props.productList && props.productList.length ? (
              <>
                {props.productList.map((productVal: any) => {
                  return (
                    <>
                      <Col xs={12}>
                        <Card className="border">
                          <Card.Header className="bg-f9f9f9">
                            <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                              <span className="fs-18 fw-bolder">
                                {productVal.business.name}
                              </span>
                              {/* <Button
                                variant="link"
                                className="btn-flush text-primary fs-16 fw-bolder"
                              >
                                Return goods to seller
                              </Button> */}
                            </div>
                          </Card.Header>
                          <ListGroup variant="flush">
                            {productVal.variants.length ? (
                              <>
                                {productVal.variants.map((variantVal: any) => {
                                  return (
                                    <>
                                      {variantVal.expiryInfo.expiring &&
                                      variantVal.expiryInfo.expiring
                                        .quantityTypes.length ? (
                                        <ListGroup.Item>
                                          <Row className="align-items-center px-4 py-3 g-4">
                                            <Col sm>
                                              <div className="d-flex align-items-center">
                                                <div className="symbol symbol-50px border me-3">
                                                  <img
                                                    src={
                                                      variantVal.variant
                                                        .media[0].url
                                                    }
                                                    className="object-fit-contain"
                                                    alt=""
                                                  />
                                                </div>
                                                <span className="fs-16 fw-600">
                                                  {variantVal.variant.title.replace(
                                                    /\s*\)\s*/g,
                                                    ')'
                                                  )}
                                                </span>
                                              </div>
                                            </Col>
                                            <Col sm="auto">
                                              <div className="d-flex flex-column fs-15 fw-600">
                                                <span>
                                                  {variantVal.expiryInfo.expiring.quantityTypes.map(
                                                    (
                                                      quantVal: any,
                                                      quantIndex: number
                                                    ) => {
                                                      return (
                                                        <>
                                                          {quantIndex !== 0
                                                            ? ' , '
                                                            : ''}
                                                          {quantVal.stockCount}{' '}
                                                          {quantVal.type ===
                                                            CartonWithDozens ||
                                                          quantVal.type ===
                                                            CartonWithPieces
                                                            ? 'cartons'
                                                            : ''}
                                                          {quantVal.type ===
                                                          Dozen
                                                            ? 'dozens'
                                                            : ''}
                                                          {quantVal.type ===
                                                          Piece
                                                            ? 'pieces'
                                                            : ''}
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </span>
                                                <span className="text-warning">
                                                  Expiring between{' '}
                                                  {Method.convertDateToDDMMYYYY(
                                                    variantVal.expiryInfo
                                                      .expiring.minDate
                                                  )}
                                                  -
                                                  {Method.convertDateToDDMMYYYY(
                                                    variantVal.expiryInfo
                                                      .expiring.maxDate
                                                  )}
                                                </span>
                                              </div>
                                            </Col>
                                          </Row>
                                        </ListGroup.Item>
                                      ) : (
                                        <></>
                                      )}
                                      {variantVal.expiryInfo.expired &&
                                      variantVal.expiryInfo.expired
                                        .quantityTypes.length ? (
                                        <ListGroup.Item>
                                          <Row className="align-items-center px-4 py-3 g-4">
                                            <Col sm>
                                              <div className="d-flex align-items-center">
                                                <div className="symbol symbol-50px border me-3">
                                                  <img
                                                    src={
                                                      variantVal.variant
                                                        .media[0].url
                                                    }
                                                    className="object-fit-contain"
                                                    alt=""
                                                  />
                                                </div>
                                                <span className="fs-16 fw-600">
                                                  {variantVal.variant.title.replace(
                                                    /\s*\)\s*/g,
                                                    ')'
                                                  )}
                                                </span>
                                              </div>
                                            </Col>
                                            <Col sm="auto">
                                              <div className="d-flex flex-column fs-15 fw-600">
                                                <span>
                                                  {variantVal.expiryInfo.expired.quantityTypes.map(
                                                    (
                                                      quantVal: any,
                                                      quantIndex: number
                                                    ) => {
                                                      return (
                                                        <>
                                                          {quantIndex !== 0
                                                            ? ' , '
                                                            : ''}
                                                          {quantVal.stockCount}{' '}
                                                          {quantVal.type ===
                                                            CartonWithDozens ||
                                                          quantVal.type ===
                                                            CartonWithPieces
                                                            ? 'cartons'
                                                            : ''}
                                                          {quantVal.type ===
                                                          Dozen
                                                            ? 'dozens'
                                                            : ''}
                                                          {quantVal.type ===
                                                          Piece
                                                            ? 'pieces'
                                                            : ''}
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </span>
                                                <span className="text-danger">
                                                  Expired between{' '}
                                                  {Method.convertDateToDDMMYYYY(
                                                    variantVal.expiryInfo
                                                      .expired.minDate
                                                  )}
                                                  -
                                                  {Method.convertDateToDDMMYYYY(
                                                    variantVal.expiryInfo
                                                      .expired.maxDate
                                                  )}
                                                </span>
                                              </div>
                                            </Col>
                                          </Row>
                                        </ListGroup.Item>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                            {/* <ListGroup.Item>
                              <Row className="align-items-center px-4 py-3 g-4">
                                <Col sm>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-50px border me-3">
                                      <img
                                        src={p1}
                                        className="object-fit-contain"
                                        alt=""
                                      />
                                    </div>
                                    <span className="fs-16 fw-600">
                                      Nutro Wafer Biscuits (Chocolate, 75g)
                                    </span>
                                  </div>
                                </Col>
                                <Col sm="auto">
                                  <div className="d-flex flex-column fs-15 fw-600">
                                    <span>10 Cartons, 15 Dozens</span>
                                    <span className="text-danger">
                                      Expiring in 6 days
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <Row className="align-items-center px-4 py-3 g-4">
                                <Col sm>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-50px border me-3">
                                      <img
                                        src={p1}
                                        className="object-fit-contain"
                                        alt=""
                                      />
                                    </div>
                                    <span className="fs-16 fw-600">
                                      Nutro Wafer Biscuits (Chocolate, 75g)
                                    </span>
                                  </div>
                                </Col>
                                <Col sm="auto">
                                  <div className="d-flex flex-column fs-15 fw-600">
                                    <span>10 Cartons, 15 Dozens</span>
                                    <span className="text-danger">
                                      Expiring in 6 days
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </ListGroup.Item> */}
                          </ListGroup>
                        </Card>
                      </Col>
                    </>
                  );
                })}
              </>
            ) : (
              <></>
            )}
            {/* <Col xs={12}>
              <Card className="border">
                <Card.Header className="bg-f9f9f9">
                  <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
                    <span className="fs-18 fw-bolder">Super Meals Limited</span>
                    <Button
                      variant="link"
                      className="btn-flush text-primary fs-16 fw-bolder"
                    >
                      Return goods to seller
                    </Button>
                  </div>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row className="align-items-center px-4 py-3 g-4">
                      <Col sm>
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50px border me-3">
                            <img
                              src={p1}
                              className="object-fit-contain"
                              alt=""
                            />
                          </div>
                          <span className="fs-16 fw-600">
                            Nutro Wafer Biscuits (Chocolate, 75g)
                          </span>
                        </div>
                      </Col>
                      <Col sm="auto">
                        <div className="d-flex flex-column fs-15 fw-600">
                          <span>10 Cartons, 15 Dozens</span>
                          <span className="text-danger">
                            Expiring in 6 days
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row className="align-items-center px-4 py-3 g-4">
                      <Col sm>
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50px border me-3">
                            <img
                              src={p1}
                              className="object-fit-contain"
                              alt=""
                            />
                          </div>
                          <span className="fs-16 fw-600">
                            Nutro Wafer Biscuits (Chocolate, 75g)
                          </span>
                        </div>
                      </Col>
                      <Col sm="auto">
                        <div className="d-flex flex-column fs-15 fw-600">
                          <span>10 Cartons, 15 Dozens</span>
                          <span className="text-danger">
                            Expiring in 6 days
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row className="align-items-center px-4 py-3 g-4">
                      <Col sm>
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50px border me-3">
                            <img
                              src={p1}
                              className="object-fit-contain"
                              alt=""
                            />
                          </div>
                          <span className="fs-16 fw-600">
                            Nutro Wafer Biscuits (Chocolate, 75g)
                          </span>
                        </div>
                      </Col>
                      <Col sm="auto">
                        <div className="d-flex flex-column fs-15 fw-600">
                          <span>10 Cartons, 15 Dozens</span>
                          <span className="text-danger">
                            Expiring in 6 days
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col> */}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ReturnGoodsSellerModal;
