import { Outlet, Route, Routes } from "react-router-dom";
import { DashboardWrapper } from "./dashboard";
const DashboardIndex = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route index element={<DashboardWrapper />} />
    </Route>
  </Routes>
);
export default DashboardIndex;
