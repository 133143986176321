/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../app/modules/auth";
import { Languages } from "./Languages";
import { toAbsoluteUrl } from "../../../helpers";
import APICallService from "../../../../api/apiCallService";
import { APIJSON } from "../../../../api/apiJSON/auth";
import { LOGOUT } from "../../../../api/apiEndPoints";

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();
  const handleLogout = async () => {
    let apiService = new APICallService(LOGOUT);
    await apiService.callAPI();
    await logout();
  };
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-900 menu-state-bg menu-state-primary fw-500  fs-16 w-160px py-2"
      data-kt-menu="true"
    >
      <div className="menu-item px-5">
        <a
          onClick={handleLogout}
          className="menu-link px-5 bg-white text-black"
        >
          Sign Out
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
