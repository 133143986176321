import { Outlet, Route, Routes } from 'react-router-dom';
import SellingProducts from './selling-products';
import DashboardWrapper from './dashboard';
import AdminDashboard from './admin-dashboard';
import TopSellers from './top-sellers';
import TopProducts from './top-products';
import TopCustomers from './top-customers';
const DashboardIndex = () => (
  <>
    <Routes>
      <Route element={<Outlet />}>
        <Route
          index
          element={<AdminDashboard />}
        />
        <Route
          path="/selling-products"
          element={
            <>
              <SellingProducts />
            </>
          }
        />
        <Route
          path="/top-customers"
          element={
            <>
              <TopCustomers />
            </>
          }
        />
        <Route
          path="/top-products"
          element={
            <>
              <TopProducts />
            </>
          }
        />
        <Route
          path="/top-sellers"
          element={
            <>
              <TopSellers />
            </>
          }
        />
      </Route>
    </Routes>
  </>
);
export default DashboardIndex;
