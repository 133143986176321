import { Button, Col, Modal, Row } from 'react-bootstrap';
import CrossSvg from '../../_admin/assets/media/close.png';
import React from 'react';
import {
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  Piece,
} from '../../utils/constants';
const ViewProducts = (props: any) => {
  return (
    <>
      <Modal
        {...props}
        show={props.products && props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-lg-794px"
        className="border-r10px"
        centered
      >
        <Modal.Header className="border-bottom-0 pb-0 text-center mx-auto">
          <img
            className="close-inner-top-2"
            width={40}
            height={40}
            src={CrossSvg}
            alt="closebutton"
            onClick={props.onHide}
          />
          <Modal.Title className="fs-26 fw-bolder mw-lg-375px pt-lg-3">
            {props.products && props.products.length} products
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-3">
          <Row className="px-lg-6 gy-6">
            {props.products && props.products.length ? (
              <>
                {props.products.map((productVal: any) => {
                  return (
                    <Col md={6}>
                      <div className="border border-r8px p-5">
                        <div className="d-flex align-items-center">
                          <div className="me-5 position-relative">
                            <div className="symbol symbol-50px border">
                              <img
                                src={productVal.media[0].url}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="ms-3">
                            <span className="fs-15 fw-600">
                              {productVal.title.replace(/\s*\)\s*/g, ')')}
                            </span>
                            <br></br>
                            <span className="fs-12 fw-600">
                              {productVal.quantityTypes.map(
                                (quantVal: any, index: number) => (
                                  <React.Fragment key={index}>
                                    {index !== 0 ? ' , ' : ''}
                                    {quantVal.stockCount}{' '}
                                    {quantVal.type === CartonWithDozens ||
                                    quantVal.type === CartonWithPieces
                                      ? quantVal.type === CartonWithPieces
                                        ? 'C(P)'
                                        : 'C(D)'
                                      : ''}
                                    {quantVal.type === Dozen ? 'D' : ''}
                                    {quantVal.type === Piece ? 'P' : ''}
                                  </React.Fragment>
                                )
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ViewProducts;
