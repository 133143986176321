/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import { FC } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { PaymentPage } from '../modules/payment-page/PaymentPage';
import { Logout, AuthPage, useAuth } from '../modules/auth';
import { App } from '../App';
import TermsAndConditions from '../modules/auth/components/terms-and-conditions';
import PrivacyPolicy from '../modules/auth/components/privacy-policy';
import {
  AssistAdmin,
  FinanceManager,
  FulfillmentManager,
  LoadingBayManager,
} from '../../utils/constants';
import { AssistAdminRoutes } from './AssistAdminRoutes';
import { FinanceAdminRoutes } from './FinanceAdminRoutes';
import { LoadingBayAdminRoutes } from './LoadingBayAdminRoutes';
import { FulfillmentAdminRoutes } from './FulfillmentAdminRoutes';
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
const AppRoutes: FC = () => {
  const { currentUser } = useAuth();
  // console.log = () => {}
  // console.error = () => {}
  // console.debug = () => {}
  // console.warn = () => {}
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route
            path="error/*"
            element={<ErrorsPage />}
          />
          <Route
            path="payment/*"
            element={<PaymentPage />}
          />
          <Route
            path="logout"
            element={<Logout />}
          />
          {currentUser ? (
            <>
              {currentUser.userType === AssistAdmin ? (
                <>
                  {currentUser?.role.some(
                    (obj: any) => obj.type === FinanceManager
                  ) ? (
                    <>
                      <Route
                        path="/*"
                        element={<FinanceAdminRoutes />}
                      />
                      <Route
                        index
                        element={<Navigate to="/sellers" />}
                      />
                    </>
                  ) : (
                    <>
                      {currentUser?.role.some(
                        (obj: any) => obj.type === LoadingBayManager
                      ) ? (
                        <>
                          <Route
                            path="/*"
                            element={<LoadingBayAdminRoutes />}
                          />
                          <Route
                            index
                            element={<Navigate to="/orders" />}
                          />
                        </>
                      ) : (
                        <>
                          {currentUser?.role.some(
                            (obj: any) => obj.type === FulfillmentManager
                          ) ? (
                            <>
                              <Route
                                path="/*"
                                element={<FulfillmentAdminRoutes />}
                              />
                              <Route
                                index
                                element={<Navigate to="/sellers" />}
                              />
                            </>
                          ) : (
                            <>
                              <Route
                                path="/*"
                                element={<AssistAdminRoutes />}
                              />
                              <Route
                                index
                                element={<Navigate to="/dashboard" />}
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Route
                    path="/*"
                    element={<PrivateRoutes />}
                  />
                  <Route
                    index
                    element={<Navigate to="/sellers" />}
                  />
                </>
              )}{' '}
            </>
          ) : (
            <>
              <Route
                path="auth/*"
                element={<AuthPage />}
              />
              <Route
                path="*"
                element={<Navigate to="/auth" />}
              />
            </>
          )}
          <Route
            path="privacy-policy"
            element={<PrivacyPolicy />}
          />
          <Route
            path="terms-and-conditions"
            element={<TermsAndConditions />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export { AppRoutes };
