/* eslint-disable jsx-a11y/anchor-is-valid */
import { Card, Col } from 'react-bootstrap';
import { Profile } from '../../../../../utils/string';
import { EditBusinessCertificate } from './edit-business-certificate';
import { EditBusinessDetails } from './edit-business-details';
import { ViewBusinessCertificate } from './view-business-certificate';
import { ViewBusinessDetails } from './view-business-details';
import { useEffect, useState } from 'react';
import { AllSeller } from '../../../../../utils/constants';
const BusinessKycDetails = (props: any) => {
  const [data, setData] = useState(props);
  return (
    <>
      <Col lg={12}>
        <Card className="border p-0 mb-5 mb-xl-10">
          <Card.Header className="bg-light">
            <Card.Title>
              <h3 className="fs-22 fw-700 m-0">{Profile.businessDetails}</h3>
            </Card.Title>
          </Card.Header>
          {props.show ? (
            <EditBusinessDetails
              handleAddressChange={props.handleAddressChange}
              editData={props.editData}
              profileData={props.profileData}
              category={props.category}
              businessTypes={props.businessTypes}
              onDetailsChange={(name: string, value: string) => {
                props.onDetailsChange(name, value);
              }}
              onCategorySelect={(event: any, action: any) => {
                props.onCategorySelect(event, action);
              }}
              onBusinessTypesSelect={(event: any) => {
                props.onBusinessTypesSelect(event);
              }}
              currentIndex={props.currentIndex}
              moduleName = {AllSeller}
            />
          ) : props.profileData ? (
            <ViewBusinessDetails profileData={props.profileData} />
          ) : (
            <></>
          )}
        </Card>
        <Card className="border p-0 ">
          <Card.Header className="bg-light">
            <Card.Title>
              <h3 className="fs-22 fw-700 m-0">
                {Profile.businessCertificate}
              </h3>
            </Card.Title>
          </Card.Header>
          <Card.Body className="p-0">
            {props.show ? (
              <EditBusinessCertificate
                editData={props.editData}
                profileData={props.profileData}
                businessCertificate={props.businessCertificate}
                onCertificateChange={props.onCertificateChange}
                onPendingCertificateChange={props.onPendingCertificateChange}
                handleShowExpiry={(index: number) => {
                  props.handleShowExpiry(index);
                }}
                currentIndex={props.currentIndex}
                handleHideExpiry={props.handleHideExpiry}
                handleExpirySelect={(value: string, index: number): void =>
                  props.handleExpirySelect(value, index)
                }
                showExpiry={props.showExpiry}
              />
            ) : props.profileData ? (
              <ViewBusinessCertificate profileData={props.profileData} />
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};
export default BusinessKycDetails;
