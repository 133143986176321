import { Col, Row } from "react-bootstrap";
import { Profile } from "../../../../../utils/string";

const ViewBusinessDetails = (props: any) => {
  return (
    <>
      <div className="card-body pt-9">
        <Row className="mb-7 align-items-center">
          <Col lg={2}>
            <label className=" fs-16 fw-500 text-dark">{Profile.name}</label>
          </Col>
          <div className="col-lg-10">
            <span className="fw-bolder fs-16 fw-600 text-dark">
              {props.profileData.business.ownerName}
            </span>
          </div>
        </Row>

        <Row className="mb-7 align-items-center">
          <Col lg={2}>
            <label className=" fs-16 fw-500 text-dark">
              {Profile.businessName}
            </label>
          </Col>
          <div className="col-lg-10 fv-row">
            <span className="fs-16 fw-600 text-dark">
              {props.profileData.business.name}
            </span>
          </div>
        </Row>

        <Row className="mb-7 align-items-center">
          <Col lg={2}>
            <label className=" fs-16 fw-500 text-dark">
              {Profile.phoneNumber}
            </label>
          </Col>
          <div className="col-lg-10 fv-row">
            <span className="fs-16 fw-600 text-dark">
              {" "}
              {props.profileData.business.phoneCountry}&nbsp;{" "}
              {props.profileData.business.phone}
            </span>
          </div>
        </Row>

        <Row className="mb-7 align-items-center">
          <Col lg={2}>
            <label className=" fs-16 fw-500 text-dark">{Profile.email}</label>
          </Col>
          <div className="col-lg-10">
            <span className="fs-16 fw-600 text-dark">
              {props.profileData.business.email}
            </span>
          </div>
        </Row>

        <Row className="mb-7 align-items-center">
          <Col lg={2}>
            <label className=" fs-16 fw-500 text-dark">
              {Profile.businessAddress}
            </label>
          </Col>
          <div className="col-lg-10">
            <span className="fs-16 fw-600 text-dark">
              {props.profileData.business.address}
            </span>
          </div>
        </Row>

        <Row className="mb-7 align-items-start">
          <Col lg={2}>
            <label className=" fs-16 fw-500 text-dark">
              {Profile.aboutBusiness}
            </label>
          </Col>
          <div className="col-lg-10">
            <span className="fs-16 fw-600 text-dark">
              {props.profileData.business.about}
            </span>
          </div>
        </Row>

        <Row className="mb-7 align-items-center">
          <Col lg={2}>
            <label className=" fs-16 fw-500 text-dark">
              {Profile.businessCategory}
            </label>
          </Col>
          <div className="col-lg-10 d-flex align-items-center">
            {props.profileData.business.categories.length ? (
              props.profileData.business.categories.map(
                (catval: any, index: number) => {
                  return (
                    <>
                      <div className="col-auto">
                        <div className="bg-gray-100 p-4 px-5 pills-radius">
                          <span className="fw-600 text-black fs-16">
                            {catval.reference.title}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                }
              )
            ) : (
              <></>
            )}
          </div>
        </Row>

        <Row>
          <Col lg={2}>
            <label className=" fs-16 fw-500 text-dark">
              {Profile.businessType}
            </label>
          </Col>
          <div className="col-lg-10">
            {props.profileData.business.types.length ? (
              props.profileData.business.types.map(
                (val: any, index: number) => {
                  return (
                    <>
                      <span className="fs-16 fw-600">
                        {val.reference.title}
                      </span>
                    </>
                  );
                }
              )
            ) : (
              <></>
            )}
            {/* <span className="fs-16 fw-600">Distributor</span> */}
          </div>
        </Row>
      </div>
    </>
  );
};

export { ViewBusinessDetails };
