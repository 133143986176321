import React, { useState } from 'react';
import { Col, Row, Card, Button, Form } from 'react-bootstrap';
import { KTSVG } from '../../../_admin/helpers';
import { BrandAndTotalSales } from '../../../utils/dummyJSON';
import { useNavigate } from 'react-router-dom';
import { CustomSelect } from '../../custom/Select/CustomSelect';
import CustomDatePicker from '../../custom/DateRange/DatePicker';
import Pagination from '../../../Global/pagination';
const TopCustomers = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(1);
  const [defaultValue, setDefaultValue] = useState('1');
  const [startDate, setStartDate] = useState<any>(new Date());
  const handleChange = (newStartDate: any) => {
    setStartDate(newStartDate);
  };
  const handleActive = (event: any) => {
    setActive(event);
  };
  const handleSelect = (event: any) => {
    setDefaultValue(event);
  };
  return (
    <>
      <div className="p-9">
        <Row className="align-items-center g-5">
          <Col
            xs
            className="fs-22 fw-bolder"
          >
            Top customers
          </Col>
          <Col xs="auto">
            <div className="d-flex align-items-center min-md-264px position-relative">
              <KTSVG
                path="/media/icons/duotune/general/gen021.svg"
                className="svg-icon-3 position-absolute ms-4"
              />
              <input
                type="text"
                id="kt_filter_search"
                className="form-control form-control-custom min-h-50px ps-12 text-dark"
                placeholder="Search by product name…"
              />
            </div>
          </Col>
        </Row>
        <Card className="bg-f9f9f9 mt-7">
          <Card.Body>
            <Row>
              <Col md={4}>
                <Form.Label className="fs-16 fw-medium">
                  Filter by business types
                </Form.Label>
                <CustomSelect
                  backgroundColor="#ffff"
                  minHeight="60px"
                />
              </Col>
              <Col md={4}>
                <Form.Label className="fs-16 fw-medium">
                  Filter by districts
                </Form.Label>
                <CustomSelect
                  backgroundColor="#ffff"
                  minHeight="60px"
                />
              </Col>
              <Col md={4}>
                <Form.Label className="fs-16 fw-medium">
                  Filter by dates
                </Form.Label>
                <CustomDatePicker
                  className="bg-white min-h-50px fs-16 fw-bold text-dark min-w-md-260px"
                  selected={startDate}
                  onChange={handleChange}
                  startDate={startDate}
                  dateFormat="dd/MM/yyyy"
                  showFullMonthYearPicker
                  inputTextBG="bg-white"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="border border-r10px mt-7">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table className="table table-row-bordered datatable align-middle gs-7 gy-4 mb-0">
                <thead>
                  <tr className="fs-16 fw-bold text-dark h-70px align-middle">
                    <th className="min-w-150px">Business name</th>
                    <th className="min-w-135px">Business type</th>
                    <th className="min-w-125px">Total orders</th>
                    <th className="min-w-125px">Products bought</th>
                    <th className="min-w-100px">Total order amount</th>
                  </tr>
                </thead>
                {BrandAndTotalSales.map((sales, index) => (
                  <tbody>
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50px border me-3">
                            <img
                              width={50}
                              height={50}
                              src={sales.brandImage}
                              alt=""
                              className="object-fit-contain"
                            />
                          </div>
                          <span className="fw-600 fs-15 mw-140px">
                            {sales.brandName}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-light border-r23px">
                          <span className="fs-15 fw-600 text-dark p-3 d-block">
                            Manufacturer
                          </span>
                        </div>
                      </td>
                      <td>
                        <span className="fs-15 fw-600">
                          {sales.totalOrders}
                        </span>
                      </td>
                      <td>
                        <div>
                          <span className="fs-15 fw-600">50 C, 120 P</span>
                          <br />
                          <Button
                            variant="link"
                            className="btn-flush fs-16 fw-600 text-decoration-underline"
                          >
                            View products
                          </Button>
                        </div>
                      </td>
                      <td>
                        <span className="fs-15 fw-600">
                          {'TSh ' + sales.totalSale}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </Card.Body>
        </Card>
        <Pagination />
      </div>
    </>
  );
};
export default TopCustomers;
