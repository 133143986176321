import React, { FC, useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import CrossGray from '../../../_admin/assets/media/svg_uTrade/cross-rounded-gray.svg';
import uploadIcon from '../../../_admin/assets/media/svg_uTrade/upload-img-white.svg';
import { CustomSelectWhite } from '../../custom/Select/CustomSelectWhite';
import ManagerAlreadyAssigned from '../../modals/manager-already-assigned';
import { Add, CustomerServices, Edit, Manager, PAGE_LIMIT, ServiceMember } from '../../../utils/constants';
import { fileValidation } from '../../../Global/fileValidation';
import clsx from 'clsx';
import APICallService from '../../../api/apiCallService';
import { customerService } from '../../../api/apiEndPoints';
import { customerServiceJSON } from '../../../api/apiJSON/customerService';
import { useNavigate } from 'react-router-dom';
import { success } from '../../../Global/toast';
import { customerServiceToast } from '../../../utils/toast';
import Method from '../../../utils/methods';
import { useAuth } from '../auth';
import PermissionModal from '../../modals/permission';
const AddNewStaff: FC = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const role = [
    {
      label: 'Manager',
      value: Manager,
    },
    {
      label: 'Service Member',
      value: ServiceMember,
    },
  ];
  const [loading, setLoading] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [managerAlreadyAssigned, setManagerAlreadyAssigned] = useState(false);
  const [managerData, setManagerData] = useState({});
  const [staffDetails, setStaffDetails] = useState<{
    name: string;
    phoneCountry: string;
    phoneNumber: string;
    email: string;
    role: any;
    districts: any;
    image: string;
    imageReader: any;
  }>({
    name: '',
    phoneNumber: '',
    phoneCountry: '+255',
    email: '',
    role: [],
    districts: [],
    image: '',
    imageReader: {},
  });
  const [validation, setValidation] = useState<{
    name: boolean;
    phoneNumber: boolean;
    email: boolean;
    image: boolean;
    role: boolean;
    districts: boolean;
  }>({
    name: false,
    phoneNumber: false,
    email: false,
    image: false,
    role: false,
    districts: false,
  });
  const [showPermissionModal,setShowPermissionModal] = useState(false);
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      if (!Method.hasPermission(CustomerServices,Add, currentUser)) {
        return window.history.back();
      }
      await fetchDistrict(page, 10);
      setFetchLoader(false);
    })();
  }, []);
  const onMenuScrollToBottom = async () => {
    if (!(districts.length === totalRecords)) {
      let tempPage = page;
      tempPage = tempPage + 1;
      setPage(tempPage);
      await fetchDistrict(tempPage, 10);
    }
  };
  const onMenuClose = async () => {
    setSearchTerm('');
  };
  const onMenuOpen = async () => {
    setPage(1);
    await fetchDistrict(1, PAGE_LIMIT, searchTerm);
  };
  const handleDistrictInputChange = async (inputValue: string) => {
    setPage(1);
    setSearchTerm(inputValue);
    await fetchDistrict(1, 0, inputValue);
  };

  const fetchDistrict = async (pageNo: number, limit: number, search: string = '') => {
    setFetchLoader(true);
    let params = {
      pageNo: pageNo,
      limit: limit,
      searchTerm: search,
      needCount: true,
    };
    let apiService = new APICallService(customerService.listDistrict, params,'','','','',CustomerServices);
    let response = await apiService.callAPI();
    // let data: any = [...districts];
    // if (pageNo === 1) {
    //   setTotalRecords(response.total);
    // } else {
    //   let prevTotal = totalRecords;
    //   setTotalRecords(prevTotal);
    // }
    // if (response.records.length) {
    //   response.records.map((val: any) => {
    //     data.push({
    //       value: val._id,
    //       label: val.name,
    //     });
    //   });
    // }
    // setDistricts(data);
    if (response.records) {
      response.records = response.records.map((val: any) => {
        return {
          label: val.name,
          value: val._id,
        };
      });
      setTotalRecords(response.total);
      let data: any = (prevResults: any) => pageNo === 1 ? response.records : [...prevResults, ...response.records]
      setDistricts(data);
    }
    setFetchLoader(false);
    // setDrivers(response.records);
    // setLoading(false);
  };
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let temp: any = { ...staffDetails };
    let tempValidation: any = { ...validation };
    tempValidation[event.target.name] = false;
    if (event.target.value.trimStart() === '') {
      tempValidation[event.target.name] = true;
    }
    temp[event.target.name] = event.target.value.trimStart();
    setStaffDetails(temp);
    setValidation(tempValidation);
  };
  const handleImgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    let tempValidation: any = { ...validation };
    let temp = { ...staffDetails };
    if (!selectedFiles) return;
    else {
      if (fileValidation(selectedFiles?.[0])) {
        temp.image = URL.createObjectURL(selectedFiles?.[0]);
        temp.imageReader = selectedFiles?.[0];
        tempValidation.image = false;
      }
    }
    setValidation(tempValidation);
    setStaffDetails(temp);
  };
  const handleImgDiscard = () => {
    let temp = { ...staffDetails };
    let tempValidation: any = { ...validation };
    temp.image = '';
    temp.imageReader = {};
    tempValidation.image = true;
    setValidation(tempValidation);
    setStaffDetails(temp);
  };
  const handleSubmit = async () => {
    let temp: any = { ...staffDetails };
    let tempValidation: any = { ...validation };
    if (temp.name === '') {
      tempValidation.name = true;
    }
    if (temp.image === '') {
      tempValidation.image = true;
    }
    if (temp.phoneNumber === '') {
      tempValidation.phoneNumber = true;
    }
    if (temp.email === '') {
      tempValidation.email = true;
    }
    if (!temp.role.length) {
      tempValidation.role = true;
    }
    if (!temp.districts.length) {
      tempValidation.districts = true;
    }
    let allTrue = Object.values(tempValidation).every((el) => el === false);
    if (allTrue) {
      setLoading(true);
      let apiService = new APICallService(
        customerService.add,
        customerServiceJSON.addMember(temp),
        '','','','',CustomerServices
      );
      let response: any = await apiService.callAPI();
      if (response) {
        navigate('/customer-service');
        success(customerServiceToast.added);
      }
      setLoading(false);
    }
    setValidation(tempValidation);
  };
  const handleOnKeyPress = (event: any) => {
    const key = event.key.toLowerCase();
    // Allow only alphabets
    if (!/[a-z ]/i.test(key)) {
      event.preventDefault();
    }
  };
  const handleRole = (event: any) => {
    let temp = { ...staffDetails };
    let tempValidation: any = { ...validation };
    let role = [event.value];
    temp.role = role;
    tempValidation.role = false;
    setValidation(tempValidation);
    setStaffDetails(temp);
  };
  const handleDistrict = async (event: any) => {
    let temp: any = { ...staffDetails };
    let tempValidation: any = { ...validation };
    if (event.length) {
      const newlySelectedOptions = event.filter(
        (option: any) => !temp.districts.includes(option.value)
      );
      let apiService = new APICallService(
        customerService.checkAssignedManager,
        newlySelectedOptions.length
          ? newlySelectedOptions[0].value
          : event[0].value,
        '','','','',CustomerServices
      );
      let response = await apiService.callAPI();
      if (response.records) {
        setManagerData({
          event: event,
          districtId: newlySelectedOptions.length
            ? newlySelectedOptions[0].value
            : event[0].value,
          index: 0,
          districtName: newlySelectedOptions.length
            ? newlySelectedOptions[0].label
            : event[0].label,
          assignedId: response.records._id,
        });
        if(!Method.hasPermission(CustomerServices,Edit,currentUser)){
          setShowPermissionModal(true);
        }else{
          setManagerAlreadyAssigned(true);
        }
        // setManagerAlreadyAssigned(true);
      } else {
        let tempDistrict: any = [];
        event.map((eventVal: any) => {
          tempDistrict.push(eventVal.value);
        });
        temp.districts = tempDistrict;
        tempValidation.districts = false;
      }
    } else {
      temp.districts = [];
      tempValidation.districts = true;
    }
    setValidation(tempValidation);
    setStaffDetails(temp);
  };
  const handleServiceMemberDistrict = async (event: any) => {
    let temp: any = { ...staffDetails };
    let tempValidation: any = { ...validation };
    temp.districts = [event.value];
    tempValidation.districts = false;
    setValidation(tempValidation);
    setStaffDetails(temp);
  };
  const handleRemoveManager = async (
    event: any,
    districtId: string,
    index: number,
    userId: string,
    districtName: string,
    assignedId: string
  ) => {
    let apiService = new APICallService(
      customerService.removeAssignedManager,
      {},
      {
        id: assignedId,
        districtId: districtId,
      },
      '','','',CustomerServices
    );
    let response = await apiService.callAPI();
    if (response) {
      setManagerAlreadyAssigned(false);
      let temp: any = { ...staffDetails };
      let tempValidation: any = { ...validation };
      if (event.length) {
        let tempDistrict: any = [];
        event.map((eventVal: any) => {
          tempDistrict.push(eventVal.value);
        });
        temp.districts = tempDistrict;
        tempValidation.districts = false;
      } else {
        temp.districts = [];
        tempValidation.districts = true;
      }
      setValidation(tempValidation);
      setStaffDetails(temp);
    }
  };
  return (
    <>
      <div className="p-9">
      {showPermissionModal && (
        <PermissionModal
        show={showPermissionModal}
        onHide={() => setShowPermissionModal(false)}
        error = {'You do not have sufficient permission for edit customer service module'}
      />
      )}
        <ManagerAlreadyAssigned
          show={managerAlreadyAssigned}
          onHide={() => setManagerAlreadyAssigned(false)}
          managerData={managerData}
          onSave={handleRemoveManager}
        />
        <Row className="g-6">
          <Col xs={12}>
            <h1 className="fs-22 fw-bolder">Add new manager / member</h1>
          </Col>
          <Col xs={12}>
            <Card className="bg-light border border-r10px">
              <Card.Body className="p-9">
                <Row className="gx-lg-9 gx-6 gy-6">
                  <Col md={6}>
                    <Row className="align-items-center">
                      <Col xs={4}>
                        <label
                          htmlFor=""
                          className="fs-16 fw-500"
                        >
                          Name
                        </label>
                      </Col>
                      <Col xs={8}>
                        <Form.Control
                          className={clsx(
                            'form-control-custom bg-white',
                            validation.name ? 'border-danger' : ''
                          )}
                          type="text"
                          placeholder="Type here..."
                          name="name"
                          value={staffDetails.name}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleInputChange(e)}
                          onKeyPress={(event: any) => {
                            handleOnKeyPress(event);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="align-items-center">
                      <Col xs={4}>
                        <label
                          htmlFor=""
                          className="fs-16 fw-500"
                        >
                          Phone number
                        </label>
                      </Col>
                      <Col xs={8}>
                        <InputGroup
                          size="lg"
                          className={clsx(
                            'border border-r8px',
                            validation.phoneNumber ? 'border-danger' : ''
                          )}
                        >
                          <InputGroup.Text
                            id="inputGroup-sizing-lg"
                            className="border-0 fs-16 fw-500 text-dark bg-white pe-0"
                          >
                            +255
                          </InputGroup.Text>
                          <Form.Control
                            className="form-control-custom bg-white border-0"
                            type="number"
                            aria-label="Large"
                            aria-describedby="inputGroup-sizing-sm"
                            placeholder="Type here…"
                            name="phoneNumber"
                            value={staffDetails.phoneNumber}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void => handleInputChange(e)}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="align-items-center">
                      <Col xs={4}>
                        <label
                          htmlFor=""
                          className="fs-16 fw-500"
                        >
                          Email
                        </label>
                      </Col>
                      <Col xs={8}>
                        <Form.Control
                          type="text"
                          className={clsx(
                            'form-control-custom bg-white',
                            validation.email ? 'border-danger' : ''
                          )}
                          placeholder="Type here..."
                          name="email"
                          value={staffDetails.email}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleInputChange(e)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="align-items-center">
                      <Col xs={4}>
                        <label
                          htmlFor=""
                          className="fs-16 fw-500"
                        >
                          Select type
                        </label>
                      </Col>
                      <Col xs={8}>
                        <CustomSelectWhite
                          options={role}
                          border={validation.role ? '#e55451' : ''}
                          onChange={handleRole}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="align-items-center">
                      <Col xs={4}>
                        <label
                          htmlFor=""
                          className="fs-16 fw-500"
                        >
                          Assign district
                        </label>
                      </Col>
                      <Col xs={8}>
                        {staffDetails.role.includes(ServiceMember) ? (
                          <CustomSelectWhite
                            border={validation.districts ? '#e55451' : ''}
                            disabled={loading}
                            loading={fetchLoader}
                            // default={districts[0] ? districts[0] : []}
                            options={districts}
                            onMenuScrollToBottom={onMenuScrollToBottom}
                            onMenuClose = {() => {
                              onMenuClose();
                            }}
                            onMenuOpen = {() => {
                              onMenuOpen();
                            }}
                            onInputChange={(newValue: any, { action }: any) => {
                              if (action === 'input-change') {
                                handleDistrictInputChange(newValue);
                              }
                            }}
                            onChange={(e: any) =>
                              handleServiceMemberDistrict(e)
                            }
                            isMulti={false}
                          />
                        ) : (
                          <CustomSelectWhite
                            border={validation.districts ? '#e55451' : ''}
                            disabled={loading}
                            loading={fetchLoader}
                            // default={districts[0] ? districts[0] : []}
                            options={districts}
                            loadingMessage={'Fetching Data'}
                            // value={districts.filter((value: any) => {
                            //   return staffDetails.districts.includes(
                            //     value.value
                            //   );
                            // })}
                            onMenuScrollToBottom={onMenuScrollToBottom}
                            onMenuClose = {() => {
                              onMenuClose();
                            }}
                            onMenuOpen = {() => {
                              onMenuOpen();
                            }}
                            onInputChange={(newValue: any, { action }: any) => {
                              if (action === 'input-change') {
                                handleDistrictInputChange(newValue);
                              }
                            }}
                            onChange={(e: any) => handleDistrict(e)}
                            isMulti={true}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="g-5">
                      <Col
                        xs={4}
                        md={4}
                      >
                        <label
                          htmlFor=""
                          className="fs-16 fw-500 mt-md-2"
                        >
                          Upload photo
                        </label>
                      </Col>
                      <Col xs={8}>
                        {staffDetails.image ? (
                          <div className="symbol symbol-168px">
                            <div className="position-relative">
                              <img
                                className="border-r10px object-fit-cover border"
                                height={168}
                                width={168}
                                src={
                                  staffDetails.image
                                    ? staffDetails.image
                                    : uploadIcon
                                }
                                alt=""
                              />
                              <Button
                                variant="link"
                                className="btn-flush close-top-2"
                                onClick={(): void => handleImgDiscard()}
                              >
                                <img
                                  src={CrossGray}
                                  alt=""
                                />
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div className="upload-btn-wrapper">
                            <div
                              className={clsx(
                                'symbol symbol-168px border',
                                validation.image ? 'border-danger' : ''
                              )}
                            >
                              <img
                                width={168}
                                height={168}
                                src={
                                  staffDetails.image
                                    ? staffDetails.image
                                    : uploadIcon
                                }
                                className="img-fluid border-r10px"
                                alt=""
                              />
                            </div>
                            <input
                              className="w-100 h-100"
                              type="file"
                              name="image"
                              accept=".png, .jpg, .jpeg"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ): void => handleImgChange(e)}
                            />
                          </div>
                        )}
                        <div className="d-flex">
                          <span className="text-gray text-italic">{`Image dimensions:-(700x700px)`}</span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col
            lg={12}
            className="pt-3"
          >
            <Button
              variant="primary"
              className="btn-lg"
              onClick={handleSubmit}
              //   onClick={() => setManagerAlreadyAssigned(true)}
              disabled={loading}
            >
              {!loading && (
                <span className="indicator-label fs-16 fw-bold">
                  Save details
                </span>
              )}
              {loading && (
                <span
                  className="indicator-progress fs-16 fw-bold"
                  style={{ display: 'block' }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default AddNewStaff;
