import { Col, Row } from 'react-bootstrap';
import uploadImg from '../../../../../_admin/assets/media/svg_uTrade/upload-file.svg';
import edit from '../../../../../_admin/assets/media/svg_uTrade/edit-round.svg';
import moment from 'moment';
import ExpiryLicence from '../../../../modals/LicenseExpiry';
const EditBusinessCertificate = (props: any) => {
  return (
    <>
      <div className="card-body bg-light p-9 pb-4">
        <Row className="align-self-center">
          {props.editData.business.certificates.length ? (
            props.editData.business.certificates.map(
              (certVal: any, index: number) => {
                return (
                  <>
                    <Col
                      lg={3}
                      md={4}
                      className="text-center mb-5"
                    >
                      <div className="fs-16 fw-500 text-black mb-3 line-clamp-1 text-start">
                        {certVal.reference.title}:
                      </div>
                      <div className="image-input image-input-outline d-flex flex-center border bg-white rounded min-w-lg-200px h-200px p-lg-3 p-2">
                        <div
                          className="image-input-wrapper shadow-none bgi-contain w-100 h-100 bgi-position-center"
                          style={{
                            backgroundImage: `url(${certVal.document.url})`,
                          }}
                        ></div>
                        <label
                          className="btn btn-icon btn-circle btn-active-color-primary w-50px h-50px "
                          data-kt-image-input-action="change"
                          title=""
                        >
                          <img
                            src={edit}
                            alt=""
                            height={33}
                            width={33}
                          />
                          <input
                            type="file"
                            name="avatar"
                            accept=".png, .jpg, .jpeg"
                            onChange={(e: any) => {
                              props.onCertificateChange(e, index);
                            }}
                          />
                          <input
                            type="hidden"
                            name="avatar_remove"
                          />
                        </label>
                      </div>
                      {certVal.expiry ? (
                        <div className="fs-16 fw-500 mt-3 d-inline-flex justify-content-center align-items-center">
                          Expires on{' '}
                          {moment(certVal.expiry).format('DD MMM, YYYY')}{' '}
                          <label
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px ms-2"
                            data-kt-image-input-action="change"
                            title=""
                          >
                            <img
                              src={edit}
                              alt=""
                              height={24}
                              width={24}
                              onClick={() => {
                                props.handleShowExpiry(index);
                              }}
                            />
                          </label>
                        </div>
                      ) : (
                        <></>
                      )}
                      <ExpiryLicence
                        showExpiry={props.showExpiry}
                        handleHideExpiry={props.handleHideExpiry}
                        handleExpirySelect={(value: string) => {
                          props.handleExpirySelect(value, props.currentIndex);
                        }}
                        value={new Date()}
                      />
                    </Col>
                  </>
                );
              }
            )
          ) : (
            <></>
          )}
          {props.editData.pendingCertificates &&
          props.editData.pendingCertificates.length ? (
            props.editData.pendingCertificates.map(
              (pendVal: any, index: number) => {
                return (
                  <Col
                    lg={3}
                    md={4}
                    className="text-center mb-5"
                  >
                    <div className="fs-16 fw-500 text-black mb-3">
                      {pendVal.title}
                    </div>
                    <div className="image-input image-input-outline d-flex flex-center border rounded w-lg-200px h-200px">
                      <div className="image-input-wrapper shadow-none bgi-contain mw-175px min-h-175px mh-175px d-flex align-items-center justify-content-center w-200px">
                        <div className="d-flex flex-column align-items-center">
                          <div className="upload-btn-wrapper text-center">
                            <input
                              type="file"
                              name={pendVal.title}
                              onChange={(e: any) => {
                                props.onPendingCertificateChange(
                                  e,
                                  index,
                                  pendVal
                                );
                                e.target.value = null;
                              }}
                            />
                            <img
                              className="img-fluid mb-3"
                              width={46}
                              height={46}
                              src={uploadImg}
                              alt=""
                            />
                            <div className="fs-15 fw-600">Click to upload</div>
                            <span className="text-gray fs-14 fw-normal fw-500">
                              Maximum file size: 5 MB
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              }
            )
          ) : (
            <></>
          )}
        </Row>
      </div>
    </>
  );
};
export { EditBusinessCertificate };
