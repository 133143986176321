import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { PageTitle } from '../../../_admin/layout/core';
// import AllProducts from "./products/all-products-finance";
import AllProducts from './all-products';
import ProductDetails from './product-details';
import EditStockCount from './../products/product-details-tabs/edit-stock-count';
import DiscountedProducts from './products/discounted-products';
import SellerProfile from '../seller/seller-profile/seller-profile';
const index = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="*"
          element={<Navigate to="/error/404" />}
        />
        {/* <Route index element={<Navigate to="/add-seller/add" />} /> */}
        <Route
          index
          element={<AllProducts />}
        />
        {/* <Route
          path="products/all-products"
          element={
            <>
              <AllProducts />
            </>
          }
        /> */}
        <Route
          path="seller-profile"
          element={
            <>
              <PageTitle>Seller Profile</PageTitle>
              <SellerProfile />
            </>
          }
        />
        <Route
          path="discounted-products"
          element={
            <>
              <DiscountedProducts />
            </>
          }
        />
        <Route
          path="product-details"
          element={
            <>
              <PageTitle>Product Details</PageTitle>
              <ProductDetails />
            </>
          }
        />
        <Route
          path="edit-stock-count"
          element={
            <>
              <PageTitle>Edit stock count</PageTitle>
              <EditStockCount />
            </>
          }
        />
      </Route>
    </Routes>
  );
};
export default index;
