import { FC } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive, KTSVG, WithChildren } from '../../../../helpers';
import { useLayout } from '../../../core';
type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  hasCount?: boolean;
  counter?: number;
};
const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  hasCount = false,
  counter,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;
  return (
    <div className="menu-item">
      <Link
        className={clsx('menu-link without-sub', { active: isActive })}
        to={to}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className="menu-icon">
            <KTSVG
              path={icon}
              className="svg-icon-2"
            />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className="menu-title">{title}</span>
        {/* Notification Number */}
        {hasCount && counter && (
          <div className="px-2 border-r5px min-w-25px text-center bg-primary">
            <span className="fs-12 fw-600 text-white">{counter}</span>
          </div>
        )}
      </Link>
      {children}
    </div>
  );
};
export { SidebarMenuItem };
